@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// FOOTER BLOCK
.footer-block {
  @include flex($direction: column, $align: center);
  position: relative;
  z-index: 3;
  background: linear-gradient(45deg, #354851, #4a6470 70%, #354851 91%);

  .main-footer {
    padding: 40px 20px;

    @media (min-width: 576px) {
      padding: 4rem;
    }

    .left-block {
      // img {
      //   max-width: 250px;
      //   margin: 0 auto;
      //   display: block;
      // }
    }

    .center-block {
      .links {
        a {
          font-family: font(main);
          font-size: size(sm);
          font-weight: 500;
          color: color(white-color);
          transition: all 0.3s ease;

          // &:hover {
          //   text-decoration: underline !important;
          // }
        }
      }
    }

    .right-block {
      .contact-block {
        .footer-company-details {
          width: fit-content;

          button {
            padding: 15px 30px !important;
            // padding: 0.75rem 2rem;
            border-radius: radius(circle) !important;
            text-wrap: nowrap;
            font-size: size(sm) !important;
            user-select: none;
          }
        }
      }

      .app-download-badges {
        .image {
          width: 150px;
          margin-bottom: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .bottom-footer {
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .copyright {
      font-family: font(main);
      font-size: size(xxxs);
    }
  }

  .input-block-container {
    .input-block {
      height: 60px;
      padding: 10px 30px !important;
      font-size: size(md) !important;
      color: color(primary-color);

      &::placeholder {
        font-size: size(sm) !important;
        font-weight: 400;
      }

      &.circle {
        border-radius: radius(circle) !important;
      }
    }

    .input-right-button {
      font-family: font(alt);
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 10px;
      padding: 7px 15px;

      &.circle {
        border-radius: radius(circle) !important;
      }
    }
  }
}

.footer-block-bg {
  background-image: url('../../assets/client/footer-image-default.svg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.popover-body {
  width: 100%;
  margin-bottom: 10px !important;
  border-radius: 14px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-family: inherit;

  .popover-btn {
    @include flex($align: center, $justify: flex-start);
    gap: 10px;
    padding: 6px 10px;
    transition: transition(alt);
    border-radius: radius(round);
    cursor: pointer;

    &:hover {

      &.phone {
        background-color: color(secondary-lightest-color);
      }

      &.email {
        background-color: color(primary-lightest-color);
      }

      svg,
      p {
        color: color(white-color) !important;
        opacity: 1;
      }
    }
  }
}

// --MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptopretina) {}

//IPAD PRO
@include media-query-for(ipadpro) {}

//IPHONE SE
@include media-query-for(iphonese) {}

//IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {}