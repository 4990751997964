@use "sass:map";

// VARIABLES

/* COLORS
* IF UPDATING KEYS IN $colors BELOW:
* UPDATE OBJECT IN: src/app/helpers/themeHelper.js
* USAGE:
*   - TopHeaderDevReference.js (src/app/components/Headers/TopHeaderDevReference.js)
*   - QuillTextEditor.js (src/app/components/QuillTextEditor.js)
*/
$colors: (
  // THEME
  "primary-color": #485d83,
  "primary-lighter-color": #E5E9F1,
  "primary-lightest-color": #E5E9F1,
  "secondary-color": #427382,
  "secondary-lighter-color": #bad7e1,
  "secondary-lightest-color": #bad7e1,
  "alt-color": #1f2a4a,
  // BASIC
  "white-color": #ffffff,
  "black-color": #151515,
  "light-color": #f6f6f6,
  "lighter-color": #f9f9f9,
  "dark-color": #17181f,
  // GREY
  "grey-color": #d0d0d0,
  "light-grey-color": #f7f7f7,
  "lighter-grey-color": #fafafa,
  "mid-grey-color": #8b8b8b,
  "dark-grey-color": #3f4254,
  // STATE
  "success-color": #52ce57,
  "danger-color": #ff4459,
  "info-color": #3699ff,
  "warning-color": #ffa800,
  // EXTRAS
  "lighter-green-color": #e2fce5,
  "light-red-color": #ff6171,
  "lighter-red-color": #ffebee,
  "blue-color": #0b4981,
  "light-blue-color": #c9e9ff,
  "lighter-blue-color": #edf7ff,
  "dark-blue-color": #052f51,
  "darker-blue-color": #031c31,
  "yellow-color": #ffcc00,
  "light-yellow-color": #ffe683,
  "lighter-yellow-color": #fff7d2,
  "orange-color": #ff9d07,
  "light-orange-color": #FFE7C2,
  // SOCIAL MEDIA
  "facebook-color": #1877f2,
  "twitter-color": #1da1f2,
  "instagram-color": #e1306c,
  "linkedin-color": #2867b2,
  // CUSTOM
  "teal-color": #427382,
  "light-teal-color": #5f8f98,
  "lighter-teal-color": #bad7e1,
  "dark-green-color": #247456,
  "darker-green-color": #184e3a,
  // BRANDS
  "ms-excel-color": #3e7f5b,
  "adobe-pdf-color": #cc3434
);
// CONVERT SCSS COLORS TO CSS VARIABLES FOR JAVASCRIPT USE
:root {
  @each $color-name, $color-value in $colors {
    --#{$color-name}: #{$color-value};
  }
}

// FONT LIST
$font-list: (
  main: #{'Inter', sans-serif},
  sub: #{'Poppins', sans-serif},
  alt: #{'Rubik', sans-serif},
  web: #{'Rubik', cursive},
  signature: #{'Satisfy', cursive},
  // client: #{'Anton', sans-serif},
  client: #{'Rubik', sans-serif}
);


/* FLUID TYPOGRAPHY
* DEFINES SCALABLE FONT SIZES BASED ON VIEWPORT WIDTH
*/
// html {
//   font-size: 16px;
//   @media (min-width: 600px) {
//     font-size: calc(16px + 0.1vw);
//   }
// }
html {
  font-size: 16px;
  @media (min-width: 600px) {
    font-size: calc(16px + 0.25vw); // SLIGHTLY INCREASE BASE FONT SIZE AS SCREEN WIDTH INCREASES
  }
  @media (min-width: 1000px) {
    font-size: calc(16px + 0.05vw); // SLIGHTLY DECREASE THE RATE OF INCREASE FOR LARGER SCREENS
  }
}

// $font-sizes: (
//   giant: clamp(3rem, calc(2rem + 2.5vw), 5rem),                     // 48px to 80px
//   xxxxl: clamp(2.75rem, calc(2rem + 2vw), 4.5rem),                  // 44px to 72px
//   xxxl: clamp(2.25rem, calc(1.75rem + 1.5vw), 4rem),                // 36px to 64px
//   xxl: clamp(2rem, calc(1.5rem + 1.25vw), 3.5rem),                  // 32px to 56px
//   xl: clamp(1.75rem, calc(1.25rem + 1vw), 2.875rem),                // 28px to 46px
//   lg: clamp(1.5rem, calc(1.125rem + 0.75vw), 2.375rem),             // 24px to 38px
//   mdlg: clamp(1.25rem, calc(1rem + 0.5vw), 1.875rem),               // 20px to 30px
//   md: clamp(1.125rem, calc(1rem + 0.25vw), 1.5rem),                 // 18px to 24px
//   sm: clamp(1rem, calc(0.9375rem + 0.1vw), 1.25rem),                // 16px to 20px
//   xs: clamp(0.875rem, calc(0.8125rem + 0.1vw), 1.125rem),           // 14px to 18px
//   xxs: clamp(0.75rem, calc(0.71875rem + 0.05vw), 1rem),             // 12px to 16px
//   xxxs: clamp(0.6875rem, calc(0.65625rem + 0.05vw), 0.875rem),      // 11px to 14px
//   xxxxs: clamp(0.625rem, calc(0.59375rem + 0.05vw), 0.75rem),       // 10px to 12px
//   xxxxxs: clamp(0.5625rem, calc(0.53125rem + 0.05vw), 0.6875rem),   // 9px to 11px
//   tiny: 0.625rem                                                    // 10px fixed
// );

// $font-sizes: (
//   giant: clamp(3.25rem, calc(2.25rem + 2.5vw), 5rem),                    // 52px to 80px
//   xxxxl: clamp(3rem, calc(2.25rem + 2vw), 4.75rem),                      // 48px to 76px
//   xxxl: clamp(2.5rem, calc(2rem + 1.75vw), 4.25rem),                     // 40px to 68px
//   xxl: clamp(2.25rem, calc(1.75rem + 1.5vw), 3.75rem),                   // 36px to 60px  // WE WANT THIS TO BE ~56PX
//   xl: clamp(2rem, calc(1.5rem + 1.25vw), 3.25rem),                       // 32px to 52px  // WE WANT THIS TO BE ~46PX
//   lg: clamp(1.75rem, calc(1.375rem + 1vw), 2.75rem),                     // 28px to 44px // WE WANT THIS TO BE ~38PX
//   mdlg: clamp(1.5rem, calc(1.25rem + 0.75vw), 2.25rem),                  // 24px to 36px // WE WANT THIS TO BE ~30PX
//   md: clamp(1.375rem, calc(1.125rem + 0.5vw), 1.875rem),                 // 22px to 30px // WE WANT THIS TO BE ~24PX
//   sm: clamp(1.25rem, calc(1rem + 0.4vw), 1.625rem),                      // 20px to 26px // WE WANT THIS TO BE ~20PX
//   xs: clamp(1.125rem, calc(0.9375rem + 0.25vw), 1.5rem),                 // 18px to 24px // WE WANT THIS TO BE ~18PX
//   xxs: clamp(1rem, calc(0.875rem + 0.1vw), 1.375rem),                    // 16px to 22px // WE WANT THIS TO BE ~16PX
//   xxxs: clamp(0.9375rem, calc(0.8125rem + 0.1vw), 1.25rem),              // 15px to 20px // WE WANT THIS TO BE ~14PX
//   xxxxs: clamp(0.8125rem, calc(0.75rem + 0.05vw), 1.125rem),             // 13px to 18px // WE WANT THIS TO BE ~12PX
//   xxxxxs: clamp(0.6875rem, calc(0.6875rem + 0.05vw), 1rem),              // 11px to 16px // WE WANT THIS TO BE ~11PX
//   tiny: 0.6875rem                                                        // 11px fixed // WE WANT THIS TO BE ~10PX
// );

$font-sizes: (
  giant: clamp(2.75rem, calc(1.875rem + 2vw), 4.5rem),                 // 44px to 72px  // median ~58px
  xxxxl: clamp(2.625rem, calc(1.875rem + 1.75vw), 4.25rem),            // 42px to 68px  // median ~55px
  xxxl: clamp(2.25rem, calc(1.625rem + 1.5vw), 3.875rem),              // 36px to 62px  // median ~49px
  xxl: clamp(2rem, calc(1.5rem + 1.25vw), 3.375rem),                   // 32px to 54px  // median ~43px
  xl: clamp(1.75rem, calc(1.375rem + 1vw), 2.875rem),                  // 28px to 46px  // median ~37px
  lg: clamp(1.5rem, calc(1.25rem + 0.75vw), 2.375rem),                 // 24px to 38px  // median ~31px
  mdlg: clamp(1.375rem, calc(1.125rem + 0.5vw), 2rem),                 // 22px to 32px  // median ~27px
  md: clamp(1.25rem, calc(1.0625rem + 0.4vw), 1.75rem),                // 20px to 28px  // median ~24px
  sm: clamp(1.125rem, calc(0.9375rem + 0.3vw), 1.625rem),              // 18px to 24px  // median ~21px
  xs: clamp(1rem, calc(0.875rem + 0.2vw), 1.375rem),                   // 16px to 20px  // median ~18px
  xxs: clamp(0.875rem, calc(0.8125rem + 0.1vw), 1.25rem),              // 14px to 18px  // median ~16px
  xxxs: clamp(0.75rem, calc(0.6875rem + 0.1vw), 1.125rem),             // 12px to 16px  // median ~14px
  xxxxs: clamp(0.6875rem, calc(0.625rem + 0.05vw), 1rem),              // 11px to 14px  // median ~12px
  xxxxxs: clamp(0.625rem, calc(0.5625rem + 0.05vw), 0.875rem),         // 10px to 12px  // median ~11px
  tiny: 0.625rem                                                       // 10px fixed    // median ~10px
);

// LINE HEIGHTS
$line-heights: (
  giant: 1.1,
  xxxxl: 1.1,
  xxxl: 1.2,
  xxl: 1.2,
  xl: 1.3,
  lg: 1.3,
  mdlg: 1.4,
  md: 1.5,
  sm: 1.5,
  xs: 1.6,
  xxs: 1.6,
  xxxs: 1.6,
  xxxxs: 1.6,
  xxxxxs: 1.6,
  tiny: 1.6
);

$border-radius: (
  primary: 7px,
  secondary: 17px,
  min: 4px,
  chipped: 5px,
  round: 7px,
  rounder: 12px,
  roundest: 20px,
  circle: 100px
);

$containers: (
  content: 100vw,
  side-nav: 260px,
  top-nav: 60px
);

$transitions: (
  primary: all 300ms ease-in 0s,
  secondary: all 100ms ease-in 0s,
  alt: all 200ms ease 0s
);

$indexes: (
  0: 10000,
  1: 9999,
  2: 9998,
  3: 9997,
  4: 9996,
  5: 9995
);


/* RANDOM VARIABLES
* TO BE MOVED TO MORE APPROPRIATE AREA OR HANDLED MORE ELEGANTLY IN FUTURE
* SOME ITEMS MAY NOT APPLY SINCE UPDATES TO CMS MASTER
*/
// $website-max-width: min(100%, 1900px);
$website-max-width: 100%;
// MENU ITEM HOVER COLOR - BEFORE
$hover-animation-underline-color--before: color(secondary-color);
// MENU ITEM HOVER COLOR - AFTER
$hover-animation-underline-color--after: color(secondary-lighter-color);
// SUBMENU ITEM COLOR
$hover-animation-underline-color--submenu: color(secondary-color);
// HEADER NAVIGATION
$header-navigation-box-shadow: 0px 5px 3px rgb(59 59 59 / 26%), 0px 9px 3px rgb(33 33 33 / 10%);
$header-navigation-background: linear-gradient(45deg, #2A4150e0, #1F3241e0 70%, #152633e0 91%);

$primary-gradient-color: radial-gradient(circle, #3C8E36 40%, #54B748 100%);
$primary-gradient-color--on-hover: radial-gradient(circle, #54B748 40%, #7FC97B 100%);
$secondary-gradient-color: radial-gradient(circle, #D77B1F 40%, #EF962C 100%);
$secondary-gradient-color--on-hover: radial-gradient(circle, #EF962C 40%, #FFB66B 100%);

// FUNCTIONS

// COLOR
@function color($color-name) {
  @return map-get($colors, $color-name);
}

// FONT
@function font($font-name) {
  @return map-get($font-list, $font-name);
}

// FONT SIZE
@function size($font-size) {
  @return map-get($font-sizes, $font-size);
}

// BORDER RADIUS
@function radius($radius) {
  @return map-get($border-radius, $radius);
}

// CONTAINER
@function container($container) {
  @return map-get($containers, $container);
}

// TRANSITION
@function transition($transition) {
  @return map-get($transitions, $transition);
}

// Z-INDEX
@function index($index) {
  @return map-get($indexes, $index);
}