@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// MAGNIFICENT TOAST
.magnificent-toast-container {
  @include flex($justify: center);
  z-index: 9999;
  position: fixed;
  bottom: 60px;
  width: 100%;
  transition: transform 1s cubic-bezier(0.9, 0.0, 0.2, 1);

  .magnificent-toast {
      background: color(white-color);
      width: 380px;
      min-height: 100px;
      padding: 25px;
      border-radius: radius(roundest);
      box-shadow: 0px 15px 25px -15px rgba(0, 0, 0, 0.15);

      .toast-crust {
          @include flex($justify: flex-end);
          width: 100%;
          position: relative;

          a {
              @include flex($justify: center);
              background: color(light-grey-color);
              position: absolute;
              width: 25px;
              height: 25px;
              top: -12px;
              right: -12px;
              border-radius: radius(circle);
          }
      }

      .toast-body {
          @include flex($justify: flex-start);
          padding: 0px;

          .icon-container {
              width: 15%;

              .icon {
                  @include flex($justify: center);
                  width: 45px;
                  height: 45px;
                  border-radius: radius(circle);
              }
          }

          .text-container {
              width: 78%;
              padding-left: 10px;

              .header {
                  font-size: size(sm);
                  font-weight: 700;
              }

              .text {
                  font-family: font(alt);
                  font-size: size(xxs);
                  font-weight: 500;
                  color: color(dark-grey-color)
              }
          }
      }
  }
}

.magnificent-toast-container.visible {
  transform: translate3d(0, 0, 0);
}

.magnificent-toast-container.hidden {
  transform: translate3d(0, 200%, 0);
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {

  // MAGNIFICENT TOAST
  .magnificent-toast-container {

      .magnificent-toast {
          margin-left: 20px;
          margin-right: 20px;
      }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD
@include media-query-for(ipad) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}