@use '../helpers/mixins' as *;
@use '../functions/theme' as *;

// FILE POND
.filepond--wrapper {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.filepond--file {
  color: color(dark-grey-color);
}

.filepond--file-action-button.filepond--file-action-button {
  border-radius: radius(round) !important;
}

.filepond--root .filepond--drop-label {
  background: color(light-color);
  border-radius: radius(rounder);
  border: 0.5px solid color(light-grey-color);
  overflow: hidden;
}

.filepond--panel-top,
.filepond--panel-center,
.filepond--panel-bottom {
  background: color(light-color);
  border: 0.5px solid color(light-grey-color);
}

.filepond--drop-label.filepond--drop-label label {
  font-family: font(main);
  font-size: size(xxxs);
  font-weight: 500;
  color: color(mid-grey-color);
}

.filepond--credits {
  display: none;
}

// REACT FLAT PICKER
.flatpickr-wrapper {
  width: 100%;
}

.flatpickr-months .flatpickr-month,
span.flatpickr-weekday,
.flatpickr-weekdays {
  background: color(primary-color);
}

// TOP ARROW
.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: color(primary-color);
}

// TODAY'S DATE
// .flatpickr-day.today {
// }

.flatpickr-months .flatpickr-month {
  height: 50px;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  // background: color(secondary-color);
  background: color(secondary-color);
  margin: 0;
}

span.flatpickr-weekday {
  font-weight: 400;
  color: color(white-color);
  opacity: 1;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: color(white-color);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background: color(secondary-color);
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: color(secondary-color);
  border-color: color(secondary-color);
}

// HAMBURGER REACT
.hamburger-react > div > div {
  height: 3.5px !important;
}

// REACT TABLE
.ReactTable {
  border-radius: radius(rounder);
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  // @include flex($justify: center, $align: center);
  @include flex($align: center);
  // text-align: center;
  white-space: unset;
  overflow: unset;
}

.ReactTable .rt-th {
  @include flex($justify: center, $align: center);
}

.ReactTable .rt-thead .rt-resizable-header-content {
  font-family: font(main);
  font-weight: 600;
}

.ReactTable .rt-noData {
  font-family: font(main);
  font-size: size(xxxs);
  text-align: center;
  color: color(mid-grey-color);
  border-radius: radius(rounder);
  box-shadow: 0px 5px 20px -5px #00000012;
  padding: 15px 20px;
}

.ReactTable.-striped.custom-highlight .rt-tr.-odd {
  background: #ebf5e2;
}

.ReactTable.-striped.custom-highlight .rt-tr.-even {
  background: #d9efc6;
}

// MINIMAL
.ReactTable {
  border-radius: 0;
  &.react-table-minimal {
    position: relative;

    &.fixed-height {
      height: 650px;

      &.min {
        height: 350px;
      }
    }

    .rt-tr-group {
      flex: unset;

      &:last-child {
        border-bottom: solid 1px rgba(0,0,0,0.05);
      }
    }

    .rt-table {
      border-radius: radius(alt);

      &::-webkit-scrollbar {
        width: 20px;
        // display: none;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        position: absolute;
        border-left: 1px solid color(light-grey-color);
        // border-top: 1px solid color(light-grey-color);
      }

      &::-webkit-scrollbar-thumb {
        // background-color: color(secondary-lighter-color);
        background-color: color(light-grey-color);
        // border-radius: radius(chipped);
        border-radius: 0;
      }
    }

    .rt-thead {
      .rt-th {
        border-bottom: solid 1px rgba(0,0,0,0.05);

        &.-sort-asc {
          box-shadow: inset 0 3px 0 0 color(secondary-lighter-color);
        }
      }
    }

    .rt-thead.-header {
      background: color(white-color);
      position: sticky;
      top: 0;
      // border-radius: radius(round);
      border-radius: 0;
      z-index: 9996;
    }

    .rt-thead .rt-resizable-header-content {
      font-family: font(main);
      font-weight: 600;
      font-size: size(xxxxs);
    }

    .rt-th,
    .rt-td {
      @include flex($justify: center);
      padding: 5px;
      font-size: size(xxxs);
      text-align: center;

      a {
        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    .-pagination .-btn {
      font-family: font(main);
      border-radius: radius(round) !important;
    }

    .-pagination .-pageInfo,
    .-pagination .-pageSizeOptions {
      margin: unset;
    }
  }
}

// MATERIAL ACCORDION
.MuiAccordion-rounded:last-child,
.MuiAccordion-rounded:first-child {
  border-radius: radius(roundest) !important;
}

.MuiAccordion-root {
  box-shadow: 0px 5px 20px -5px #00000026 !important;
}

.MuiAccordion-root:before {
  content: none !important;
}

.MuiAccordionDetails-root.material-accordion-minimal {
  padding: 8px 5px 16px !important;
}

/* FOLDER TREE COMPONENT */
.MuiTreeView-root {
  max-width: 600px !important;
}

.MuiTreeItem-iconContainer {
  width: 50px !important;
}

.MuiTreeItem-content {
  margin-bottom: 10px;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  padding: 5px 15px;
  border-radius: radius(round);
  background: color(light-grey-color) !important;
}

.label-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-block .label-container {
  width: 65%;
}

.label-block .label-container p {
  font-family: font(main);
  font-size: size(md);
  font-weight: 500;
}

.label-block .actions-container {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.label-block .actions-container a {
  display: flex;
  align-items: center;
}

// FILTER ACCORDION
.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-button:focus {
  border-radius: 3px !important;
}

// SIGNATURE PAD
.signature-pad-wrapper {
  background-color: color(light-grey-color);
  border: 2px solid color(grey-color);
  border-radius: radius(round);
  padding: 20px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;

  canvas {
    height: 290px;
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {
}

// LAPTOP
@include media-query-for(laptop) {
}

// IPAD PRO
@include media-query-for(ipadpro) {
}

// IPHONE SE
@include media-query-for(iphoneSE) {
  // MATERIAL ACCORDION
  .MuiAccordion-root {
    .content-header {
      .text-header--md,
      .text-header--lg {
        margin-top: 0;
      }
    }
  }

  .MuiAccordion-rounded {
    padding-bottom: 15px;
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}