@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// HOMEPAGE
.with-side-bar {
  @include flex($align: flex-start);

  .static-banner {
    &.dynamic {
      min-height: 500px !important;
    }
  }
}

.deal-listing-block {
  width: 75%;
}

.side-items-block {
  width: 25%;
  height: 100%;
  margin-left: 30px;

  // APP STORE BADGE
  .app-download-block {
    @include flex($direction: column, $justify: center);
    width: 100%;
    height: 500px;
    background: color(white-color);
    padding: 30px;

    .app-badges {
      margin-top: 30px;
      text-align: center;
    }

    .image {
      width: 220px;
      margin-bottom: 20px;
    }
  }

  // BLOG SIDE STRIP
  .blog-side-strip {
    margin-top: 30px;
    background: color(white-color);
    padding: 30px;
  }

  // HEADERS
  .text-block {
    .header {
      font-size: size(lg);
    }
  }
}

// CUSTOM FEATURES
.cta-block {
  position: relative;

  .text-block {
    .header {
      font-size: size(xl);
    }
  }

  .signature {
    font-family: font(signature);
    font-size: size(xl);
    position: absolute;
    top: 20px;
    right: -80px;
    transform: rotate(-10deg);
  }
}

// SQUARE CARD : CAROUSEL IMAGES
.carousel-images {
  width: 150px !important;
  height: 150px !important;
}

.custom-banner {
  min-height: 100vh;
}

// HOMEPAGE CUSTOM BANNER
// .custom-banner-container {
//   position: relative;

//   .custom-banner {
//     display: block;

//     .content-section {
//       position: absolute;
//       top: 150px;
//       left: 50%;
//       transform: translateX(-50%);
//       width: 95%;
//       padding: 20px;


//       .details-block {
//         @include flex($direction: column, $align: center);
//         width: 100%;

//         .custom-banner-description {
//           line-height: 45px;
//           font-size: 30px;

//           @media screen and (max-width: 700px) {
//             text-align: left;
//             line-height: 36px;
//             font-size: 27px;
//           }
//         }
//       }

//       .custom-banner-content {
//         .custom-banner-title-container {
//           @include flex($align: baseline, $justify: flex-start, $wrap: wrap);
//           column-gap: 12px;

//           .custom-banner-title {
//             font-size: clamp(32px, 3vw, 50px);

//             span {
//               font-size: clamp(42px, 4vw, 60px);
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .custom-banner-title {
//   line-height: 65px !important;
// }


// .custom-banner-description {
//   max-width: 700px;
//   // margin-left: 50px;
//   color: color(light-color);
//   text-align: center;
// }

// .responsive-content-section {

//   // min-height: 500px;
//   .italics {
//     font-style: italic;
//   }

//   .background-decoration--dark-light-waves {
//     position: absolute;
//     top: -60px;
//     left: 0;
//     width: 100%;
//   }
// }

// CUSTOM BANNER INSERT
.custom-banner-insert {
  min-height: 100vh;
}

.homepage-section_about-intro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  padding: 60px 200px;
  gap: 50px;

  &__content {
    flex: 2;

    .description {
      font-size: size(sm);
    }
  }

  &__image {
    flex: 1;
  }

  img {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border-radius: 50%;
  }
}

// CONTENT SECTION - WEBSITE INTRO
.mult-intro {
  width: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  .listing-block {
    @include flex($align: stretch, $justify: flex-start, $wrap: wrap);
    gap: 50px;
    row-gap: 30px;
    position: relative;
    z-index: 2;

    .item {
      flex: 1;
      width: 100%;
      font-family: font(web);
      border-radius: radius(roundest);
      background-color: color(white-color);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      cursor: pointer;

      .svg-container {
        width: fit-content;
        margin: 0 auto;

        img {
          height: 100px;
          opacity: 0.9;
        }
      }

      .header {
        color: color(mid-grey-color);
        text-transform: uppercase;
        opacity: 0.9;
      }

      hr {
        color: color(grey-color);
        height: 5px;
        width: 100%;
        max-width: 100px;
        transition: transition(primary);
      }

      &:hover {

        .header,
        hr {
          color: color(primary-color);
        }
      }
    }

    @media screen and (max-width: 900px) {
      @include flex($direction: column);

      .item {
        padding: 15px;
      }
    }
  }
}

// CONTENT SECTION - INTRO TO ABOUT
.intro-to-about {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  padding: 30px;
}

// --MEDIA QUERIES
@media screen and (max-width: 1150px) {
  .custom-banner-title {
    line-height: 65px !important;
  }

  .featured-listing {
    .item {
      width: 50%;
      flex: none;
    }
  }
}

@media screen and (max-width: 900px) {
  .custom-banner-container {
    .content-section {
      padding: 0;
      width: 90%;
      margin: 0 auto;

      .custom-banner-title-container {
        .custom-banner-title {
          line-height: 45px !important;
        }
      }
    }
  }

  // .background-decoration-container {
  //   width: 100%;
  // }
}

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptopretina) {}

//IPAD PRO
@include media-query-for(ipadpro) {}

//IPHONE SE
@include media-query-for(iphonese) {

  // MOBILE : HOMEPAGE
  .with-side-bar {
    flex-direction: column;
    justify-content: center;

    // BANNER
    .static-banner {
      &.dynamic {
        min-height: 400px !important;
      }
    }

    .product-carousel {
      padding-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .deal-listing-block,
  .side-items-block {
    width: 100%;
  }

  .side-items-block {
    margin-left: 0px;

    // SIDE ITEM CONTAINERS
    .app-download-block,
    .blog-side-strip {
      border-radius: radius(rounder);
    }

    // APP STORE BADGE
    .app-download-block {
      height: 100%;
      padding: 30px;
      padding-top: 40px;

      .app-badges {
        margin-top: 15px;
      }

      .image {
        width: 240px;
        margin-bottom: 15px;
      }
    }

    // BLOG SIDE STRIP
    .blog-side-strip {
      margin-top: 30px;
      background: color(white-color);
      padding: 30px;
    }

    // HEADERS
    .text-block {
      .header {
        font-size: size(mdlg);
      }
    }
  }

  // CUSTOM FEATURES
  .cta-block {
    margin-bottom: 30px;

    .text-block {
      .header {
        font-size: size(lg);
      }
    }

    .image-card-carousel {
      padding-top: 60px;
    }

    .signature {
      font-size: size(mdlg);
      top: 90px;
      right: 0px;
      transform: rotate(0deg);
    }
  }

  // SQUARE CARD : CAROUSEL IMAGES
  .carousel-images {
    width: 120px !important;
    height: 120px !important;
  }

  .custom-banner-title {
    span {
      margin-left: 0px !important;
    }
  }

  .custom-banner-description {
    // max-width: 700px;
    margin-left: 0;
  }

  // FEATURED LISTING
  .featured-listing {
    .item {
      height: 250px;
      padding: 10px;
      text-align: left;

      p {
        font-size: size(sm);
        line-height: 25px !important;
      }
    }
  }

  // .background-decoration-container {
  //   width: 250%;
  // }
}

//IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {}