@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// DRAWER NAVIGATION
.drawer-navigation {
  @include flex($direction: column, $justify: space-between);
  gap: 20px;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: fit-content;
  max-width: 90%;
  height: 100%;
  box-shadow: 5px 5px 15px -5px rgb(0 0 0 / 19%);
  // transform: translate3d(-100%, 0, 0);
  // transition: transform 0.6s cubic-bezier(0.9, 0, 0.1, 1);
  transform: translateX(-100%);
  transition: transform 0.4s cubic-bezier(0, 0.99, 0.25, 1.4);

  &.open {
    // transform: translate3d(0, 0, 0);
    transform: translateX(0);

    // HEADER ITEMS - LOGO BLOCK
    .drawer-header {
      width: 100%;
      max-width: 400px;
      opacity: 0.85;
      padding: 20px 20px 0 20px;

      .logo-block {
        .mobile-drawer-logo-container {
          width: 85%;
          margin: 0 auto;
        }
      }
    }

    // CHILDREN - MENU ITEMS
    .drawer-menu-items-container {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      overflow-y: scroll;

      // ABS POSITIONED ICONS IN DRAWER
      .drawer-menu-icon {
        font-size: 1.3rem;
        stroke-width: 2.5;
        position: absolute;
        top: 50%;
        left: -28px;
        z-index: 1;
        transform: translateY(-50%);
      }

      // SCROLL SHADOWS Y
      background: 
        linear-gradient(#FFFFFF 33%, rgba(247,247,247, 0)),
        linear-gradient(rgba(247,247,247, 0), #FFFFFF 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(59,59,59, 0.5), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(59,59,59, 0.5), rgba(0,0,0,0)) 0 100%;
      background-color: #FFFFFF;
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
      background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
      
      // SCROLLBAR
      // scrollbar-width: thin !important;
      // scrollbar-color: color(dark-grey-color) transparent !important;


      // .drawer-menu-items {
      //   padding: 20px 4vw;

      //   li {
      //     margin-bottom: 15px;

      //     a,
      //     .button {
      //       display: inline-block;
      //       min-width: unset;
      //       min-height: unset;
      //       font-weight: 500;
      //       font-size: size(sm);
      //       text-align: left;
      //       padding: 10px 0px !important;

      //       &::after {
      //         content: '';
      //         position: absolute;
      //         width: 100%;
      //         transform: scaleX(0);
      //         height: 3px;
      //         bottom: -5px;
      //         left: 0;
      //         background-color: color(primary-lighter-color);
      //         border-radius: radius(circle);
      //         transform-origin: right;
      //         transition: transform 200ms ease-out;
      //       }

      //       &:hover::after {
      //         transform: scaleX(1);
      //         transform-origin: left;
      //       }
      //     }
      //   }

      //   .submenu-block {
      //     position: relative;
      //     min-width: 200px;
      //     border: 1px solid color(light-grey-color);

      //     &.visible {
      //       display: flex;
      //     }

      //     &.hidden {
      //       display: none;
      //     }

      //     .submenu-items-block {
      //       li {

      //         a,
      //         .button {
      //           font-size: size(xs);
      //           padding: 0px !important;
      //           color: color(mid-grey-color) !important;

      //           &.full-width {
      //             width: 100%;
      //           }

      //           &::after {
      //             content: '';
      //             position: absolute;
      //             width: 100%;
      //             transform: scaleX(0);
      //             height: 3px;
      //             bottom: -5px;
      //             left: 0;
      //             background-color: color(primary-lightest-color);
      //             border-radius: radius(circle);
      //             transform-origin: right;
      //             transition: transform 200ms ease-out;
      //           }

      //           &:hover::after {
      //             transform: scaleX(1);
      //             transform-origin: left;
      //           }
      //         }
      //       }

      //       // DEFAULT
      //       &.default {
      //         li {
      //           padding: 10px 0 !important;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    // FOOTER ITEMS - SOCIAL BLOCK
    .drawer-footer {
      width: 100%;
      padding: 0 20px 20px 20px;

      .social-block {
        @include flex($justify: space-around, $align: center);
        overflow: hidden;
        overflow-x: scroll;
        width: 100%;
        padding: 20px;
        border-radius: radius(roundest);
        background: color(light-grey-color);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

        .social-icon-set {
          flex-wrap: nowrap !important;
        }
      }
    }
  }
}