@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// NAV BLOCK
.nav-block-container {
  .nav-block {
    @include flex();
    position: relative;
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: radius(round) !important;
    transition: 200ms ease 0s;

    &:hover {
      background: color(lighter-blue-color) !important;

      .content-block-container {
        .icon-left-container {
          .nav-tooltip {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .content-block-container {
      @include flex($justify: flex-start);

      .icon-left-container {
        position: relative;
        margin-right: 15px;

        .nav-tooltip {
          z-index: index(2);
          position: absolute;
          top: 0;
          left: -15px;
          min-width: 130px;
          padding: 5px 10px;
          border-radius: radius(chipped);
          transform: translate3d(45%, 0, 0);
          transition: all 200ms ease 0s;
          box-shadow: 0px 10px 30px -5px #00000026;
          opacity: 0;
          visibility: hidden;

          p {
            font-family: font(alt);
            font-size: size(xxxxs);
          }
        }
      }

      .title-container {
        .title {
          font-size: size(xxxs);
          font-weight: 500;
          text-align: left;
        }
      }
    }

    .nav-icon-container {
      margin-right: -8px;
    }
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}
