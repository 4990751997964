@use './mixins' as *;
@use '../functions/theme' as *;

// FONT-WEIGHT
$weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

@each $weight in $weights {
    .fw-#{$weight} {
        font-weight: $weight !important;
    }
}

// FONT SIZES
$sizes: 'giant', 'xxxxl', 'xxxl', 'xxl', 'xl', 'lg', 'mdlg', 'md', 'sm', 'xs', 'xxs', 'xxxs', 'xxxxs', 'xxxxxs', 'tiny';
// $sizes: 'xxxl', 'xxl', 'xl', 'lg', 'mdlg', 'md', 'sm', 'xs', 'xxs', 'xxxs';

// LINE-HEIGHTS
// $line-heights: (
//   xxxl: 1.5,
//   xxl: 1.5,
//   xl: 1.5,
//   lg: 1.5,
//   mdlg: 1.6,
//   md: 1.5,
//   sm: 1.4,
//   xs: 1.5,
//   xxs: 1.5,
//   xxxs: 1.6,
// );

@each $size in $sizes {
  .size-#{$size} {
    font-size: size($size) !important;
    line-height: map-get($line-heights, $size);
  }
}

h1 {
  line-height: 1.2 !important;
}

h2 {
  line-height: 1.3 !important;
}

h3 {
  line-height: 1.4 !important;
}

h4 {
  line-height: 1.5 !important;
}

h5 {
  line-height: 1.6 !important;
}

h6 {
  line-height: 1.6 !important;
}

// FONT FAMILY
@each $name, $font in $font-list {
  .font-#{$name} {
    font-family: #{$font} !important;
  }
}

.main-font {
    font-family: font(main) !important;
}

.sub-font {
    font-family: font(sub) !important;
}

.alt-font {
    font-family: font(alt) !important;
}



// TEXT TRANSFORM
.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}

// TEXT ALIGN

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD
@include media-query-for(ipad) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}