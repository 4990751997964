@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// OVERLAY
.overlay {
  z-index: 9998;
  height: 100%;
  width: 100%;
  position: fixed;
  content: '';
  top: 0;
  right: 0;
}

.overlay.normal {
  background: #0000004d;
}

.overlay.dark {
  background: #00000090;
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}