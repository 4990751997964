@use '../helpers/mixins' as *;
@use '../functions/theme' as *;

// MATERIAL REACT
.MuiFormControl-root {
  min-width: 100% !important;
}

// MATERIAL UI TABLE
.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiButton-root,
.MUIDataTableHeadCell-data-154,
.MuiTableCell-head,
.MUIDataTableHeadCell-fixedHeader-151 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

.MuiTableCell-root {
  font-size: 1.1rem !important;
}

.MuiTableFooter-root .MuiTableCell-root:last-of-type {
  border-bottom: none;
}

.MuiTableCell-root.MuiTableCell-footer.MUIDataTablePagination-tableCellContainer-202 {
  border-color: var(--white-color);
}

.MuiTypography-body2 {
  font-size: 1rem !important;
}

#Path-94 {
  fill: var(--green-color);
}

#Rectangle-185,
#Rectangle-185-Copy {
  fill: var(--red-color);
  opacity: 1;
}

// CHECKBOX CONTAINER
.MuiTableCell-paddingCheckbox {
  background: color(white-color) !important;
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {
}

// LAPTOP
@include media-query-for(laptop) {
}

// IPAD PRO
@include media-query-for(ipadpro) {
}

// IPHONE SE
@include media-query-for(iphoneSE) {
  // TABLE ACTIONS
  .MuiTableCell-root {
      .content-container--actions {
          .button,
          button.button {
              &:first-of-type {
                  &--lg,
                  &--md,
                  &--sm,
                  &--xs,
                  &--xxs {
                      margin-bottom: 10px;
                  }
              }
          }
      }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}