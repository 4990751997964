@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// CONTENT PAGE - ABOUT THE CHAMBER
.content-page_about-the-chamber {
  // STATEMENTS BLOCK
  .statements-block {
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    margin: 0 auto;
    padding-top: 100px;

    .statement {
      @include flex($direction: column, $justify: flex-start, $align: center);
      min-width: 250px;
      border-radius: radius(roundest);
      .icon-wrapper {
        @include flex($justify: center, $align: center);
        width: 120px;
        height: 120px;
        padding: 20px;
        border-radius: 100px;
        &.mission {
          background-color: color(primary-lighter-color);
          box-shadow: 0 0 12px 6px color(primary-lighter-color);
        }
        &.vision {
          background-color: color(secondary-lighter-color);
          box-shadow: 0 0 12px 6px color(secondary-lighter-color);
        }
        .icon {
          width: 100%;
          height: auto;
        }
      }
      .header {
        font-size: size(lg);
      }

      .description {
        font-size: size(md);
        line-height: 32px;
        text-align: center;
      }
    }
  }
}

// --MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {
}

// LAPTOP
@include media-query-for(laptopretina) {
}

//IPAD PRO
@include media-query-for(ipadpro) {
}

//IPHONE SE
@include media-query-for(iphonese) {
  // MOBILE : HEADER NAVIGATION
  .content-page_about-the-chamber {
    .content-section_statements {
      padding: 30px 30px;
    }
  }

  .accordion {
    h3 {
      font-size: size(sm);
    }
  }
}

//IPHONE X
@include media-query-for(iphonex) {
}

// IPAD PORTRAIT
@include media-query-for(ipad) {
}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {
}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {
}
