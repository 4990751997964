@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// SUB MENU BLOCK
.submenu-block {
  z-index: index(2);
  position: fixed;
  display: none;
  min-width: 260px;
  padding: 10px;
  margin-top: 0;
  border-radius: radius(round);
  border: 1px solid color(light-grey-color);
  box-shadow: 0 10px 30px -15px rgb(0 0 0 / 30%);

  &.visible {
    display: block;
  }

  .submenu-items-block {
    padding-left: 0;

    .nav-block {
      
      &:hover {
        background: color(lighter-grey-color) !important;
      }
    }
  }
}

// HOVER STATE
.has-submenu {
  &:hover .submenu-block {
    display: block;
  }

  &:hover .button {
    color: color(black-color) !important;
    background: color(lighter-blue-color);
  }

  .button {
    padding-right: 10px !important;

    svg {
      margin-right: 0;
      margin-left: 5px;
    }
  }
}
