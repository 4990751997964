@use '../helpers/mixins' as *;
@use '../functions/theme' as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  // overflow: auto;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: font(main);
  // overflow-y: hidden;
  overflow-x: hidden;
}

a {
  color: unset;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 200ms ease 0s;

  &:hover {
    color: unset;
  }
}

p {
  margin-bottom: 0 !important;
}

li {
  list-style: none;
}

button {
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}

::-webkit-scrollbar {
  // width: 15px;
  display: none;
}

::-webkit-scrollbar-track {
  background-color: color(light-grey-color);
  background: transparent;
  position: absolute;
  // border-left: 1px solid color(light-grey-color);
}

::-webkit-scrollbar-thumb {
  background-color: color(grey-color);
  border-radius: radius(min);
}