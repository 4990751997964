@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// INPUT BLOCK
.input-block-container {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  position: relative;

  &.minimal {
    margin: 0;

    .input-block {
      // height: 40px;
      height: 52px;

      &.padded {
        margin-top: 0;
        margin-bottom: 0;
        font-size: size(xxxs);
      }
    }

    .input-block.textarea {
      height: 45px;
    }

    .icon-right-container {
      margin-top: -6px !important;
      width: 27px !important;
    }

    &--padded {
      margin-right: 5px;

      .input-block {
        height: 40px;
  
        &.padded {
          margin-top: 0;
          margin-bottom: 0;
          font-size: size(xxxs);
        }
      }
  
      .icon-right-container {
        margin-top: -6px !important;
        width: 27px !important;
      }
    }

    &.stripped {
      .input-block {
        padding: 5px !important;
        // font-size: size(tiny);
        font-size: size(xxxxs);
        text-align: center;
      }
    }
  }

  .static-floating-label {
    z-index: 1;
    position: relative;
    top: 7px;
    left: 15px;
    color: color(mid-grey-color);
    background: color(white-color);
    // font-size: size(tiny);
    font-size: size(xxxxs);
    font-weight: 600;
    border-radius: radius(round);
    padding: 5px 12px;
    transition: all 400ms ease 0s;
  }

  .static-label {
    font-size: size(xxs);
    font-weight: 600;
  }

  .static-floating-label.error,
  .static-label.error {
    color: color(light-red-color);
  }

  .static-floating-label.warning,
  .static-label.warning {
    color: color(warning-color);
  }

  .static-floating-label.success,
  .static-label.success {
    color: color(success-color);
  }

  .static-floating-label.disabled,
  .static-label.disabled {
    // color: color(grey-color);
  }

  .input-block {
    height: 52px;
    width: 100%;
    font-size: size(xs);
    font-weight: 500;
    color: color(dark-grey-color);
    border: none;
    border-radius: radius(primary);
    padding: 15px;
    transition: all 100ms ease-in 0s;
    -webkit-appearance: none !important;

    &:focus {
      color: color(black-color);
      border-color: inherit;
      outline: 0;
      box-shadow: none;
    }

    &::placeholder {
      color: color(grey-color);
      font-size: size(xxs);
    }

    &[type="password"] {
      font-size: size(xs);
    }

    &[type="secure-text"] {
      -webkit-text-security: disc;
      text-security: disc;
    }
  }

  .input-block.textarea {
    @extend .input-block;
    height: 150px;
  }

  .input-block.textarea.sm {
    @extend .input-block;
    height: 110px;
  }

  .input-block.bordered {
    border: 0.5px solid color(grey-color);
  }

  .input-block.error {
    color: color(light-red-color);
    border: 2px solid color(light-red-color);
  }

  .input-block.warning {
    @extend .input-block, .error;
    color: color(orange-color);
    border-color: color(warning-color);
  }

  .input-block.success {
    @extend .input-block, .error;
    color: color(dark-grey-color);
    border-color: color(success-color);
  }

  .input-block.disabled,
  .input-block.disabled.sm {
    @extend .input-block, .error;
    color: color(black-color) !important;
    // color: color(mid-grey-color) !important;
    border-color: color(light-grey-color) !important;
    background-image: linear-gradient(
      45deg,
      #f3f6f9 25%,
      #fbfbfb 25%,
      #fbfbfb 50%,
      #f3f6f9 50%,
      #f3f6f9 75%,
      #fbfbfb 75%,
      #fbfbfb 100%
    ) !important;
    background-size: 40px 40px !important;
    cursor: not-allowed !important;
    opacity: 1 !important;
  }

  .input-block.padded {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .input-state-text {
    font-size: size(xxxxs);
    font-weight: 600;
    display: block;
    margin-top: 5px;
    margin-left: 5px;
  }

  .input-state-text.unpadded {
    margin-top: -5px;
  }

  .error-message {
    @extend .input-state-text;
    color: color(danger-color);
  }

  .warning-message {
    @extend .input-state-text;
    color: color(warning-color);
  }

  .success-message {
    @extend .input-state-text;
    color: color(success-color);
  }

  // ICON RIGHT
  .icon-right-container {
    @include flex($justify: center);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 5px;
    width: 45px;
    height: 50px;
  }

  // ICON LEFT
  .icon-left-container {
    @include flex($justify: center);
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-left: 5px;
    width: 45px;
    height: 50px;
  }

  // INPUT RIGHT BUTTON
  .input-right-button {
    color: color(mid-grey-color);
    background: color(white-color);
    font-size: size(xxxxxs);
    width: max-content;
    position: absolute;
    right: 0;
    // padding: 5px 15px;
    padding: 5px 10px;
    margin-top: 2px;
    margin-right: 5px;
    border-radius: radius(chipped) !important;

    &:hover {
      // background: darken(color(light-grey-color), 2%);
      background: color(grey-color);
    }
  }

  // REACT SELECT OVERRIDE
  .css-yk16xz-control {
    font-size: size(xs);
    min-height: 52px;
    // background: color(light-color) !important;
    transition: none;
    border-radius: radius(primary);
    border: 1px solid color(light-color);
    padding-left: 15px;
    // margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .css-yk16xz-control.bordered {
    border: 0.5px solid color(grey-color);
  }

  // FOCUSED STATE
  .css-1pahdxg-control {
    font-size: size(xs);
    min-height: 52px;
    background: color(light-color);
    border: 1px solid color(info-color);
    border-radius: radius(primary);
    padding-left: 15px;
    // margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .css-1wa3eu0-placeholder {
    margin-top: 0px;
  }

  .css-26l3qy-menu {
    z-index: 9999;
    border-radius: radius(rounder) !important;
    overflow: hidden;
  }

  .css-tlfecz-indicatorContainer {
    padding-top: 10px;
  }

  .css-1uccc91-singleValue,
  .css-1wa3eu0-placeholder {
    margin-left: -8px !important;
  }

  .css-1okebmr-indicatorSeparator,
  .css-109onse-indicatorSeparator.disabled {
    display: none;
  }

  // FOCUSED STATE
  .css-1pahdxg-control.__control.__control--is--focused.__control-menu-is-open {
    padding-top: 5px;
  }

  // DISABLED STATE
  .css-1fhf3k1-control {
    @extend .css-yk16xz-control;
    @extend .css-1pahdxg-control;
  }

  .css-14jk2my-container.disabled,
  .css-g1d714-ValueContainer.disabled,
  .css-1wa3eu0-placeholder.disabled,
  .css-1hb7zxy-IndicatorsContainer.disabled,
  .css-tlfecz-indicatorContainer.disabled {
    background: none !important;
    overflow: hidden;
  }

  .css-tlfecz-indicatorContainer.disabled {
    // padding-top: 5px;
    // padding-right: 10px;
  }

  .css-1wa3eu0-placeholder.disabled {
    margin-top: -2px;
    opacity: 0.5;
  }

  .css-107lb6w-singleValue.disabled {
    color: color(mid-grey-color) !important;
    background: none !important;
    margin-left: -8px !important;
  }

  .css-yk16xz-control.error {
    color: color(light-red-color);
    border: 2px solid color(light-red-color);
  }

  .css-yk16xz-control.warning {
    @extend .css-yk16xz-control, .error;
    color: color(orange-color);
    border-color: color(warning-color);
  }

  .css-yk16xz-control.success {
    @extend .css-yk16xz-control, .error;
    color: color(dark-grey-color);
    border-color: color(success-color);
  }

  .css-yk16xz-control.disabled {
    @extend .css-yk16xz-control, .error;
    border-color: color(light-grey-color) !important;
    background-image: linear-gradient(
      45deg,
      #f3f6f9 25%,
      #fbfbfb 25%,
      #fbfbfb 50%,
      #f3f6f9 50%,
      #f3f6f9 75%,
      #fbfbfb 75%,
      #fbfbfb 100%
    ) !important;
    background-size: 40px 40px !important;
    cursor: not-allowed;
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {
  .input-block-container {
    margin-left: 0;
    margin-right: 0;

    .input-block,
    .css-yk16xz-control,
    .css-1pahdxg-control {
      font-size: size(xxxs);

      &::placeholder {
        font-size: size(xxxs);
      }
    }

    .static-label {
      font-size: size(xxxs);
    }
  }

  // SELECT
  .alt-select {
    .css-1wa3eu0-placeholder {
      font-size: size(xxxxs);
    }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {
}

// IPAD PORTRAIT
@include media-query-for(ipad) {
  // INPUT BLOCK
  .input-block-container {
    .input-block,
    .css-yk16xz-control,
    .css-1pahdxg-control {
      font-size: size(xxxxxs);

      &::placeholder {
        font-size: size(xxxxxs);
      }
    }

    .static-label {
      font-size: size(xxxxxs);
    }
  }
}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {
  // INPUT BLOCK
  .input-block-container {
    .input-block,
    .css-yk16xz-control,
    .css-1pahdxg-control {
      font-size: size(xxxs);

      &::placeholder {
        font-size: size(xxxs);
      }
    }

    .static-label {
      font-size: size(xxxxs);
    }

    &.minimal {
      // margin: 0;
  
      // .input-block {
      //   height: 40px;
  
      //   &.padded {
      //     margin-top: 0;
      //     margin-bottom: 0;
      //     font-size: size(xxxs);
      //   }
      // }
  
      // .input-block.textarea {
      //   height: 45px;
      // }
  
      // .icon-right-container {
      //   margin-top: -6px !important;
      //   width: 27px !important;
      // }
  
      // &--padded {
      //   margin-right: 5px;
  
      //   .input-block {
      //     height: 40px;
    
      //     &.padded {
      //       margin-top: 0;
      //       margin-bottom: 0;
      //       font-size: size(xxxs);
      //     }
      //   }
    
      //   .icon-right-container {
      //     margin-top: -6px !important;
      //     width: 27px !important;
      //   }
      // }
  
      &.stripped {
        .input-block {
          font-size: size(xxxs);
        }
      }
    }
  }
}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}