@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// TEXT SECTION
.text-block-container {
  position: relative;

  .text-block {

    // ALIGN LEFT
    &.left {
      text-align: left;
    }

    // ALIGN CENTER
    &.center {
      text-align: center;
    }

    // ALIGN RIGHT
    &.right {
      text-align: right;
    }

    // HEADER
    .header {
      // font-family: font(client);
      font-size: size(xl);
      font-weight: 700 !important;
      color: color(dark-color);
      // line-height: 50px;

      &.padded {
        padding-bottom: 10px;
      }
    }

    // SUBHEADER
    .subheader {
      font-family: font(client);
      font-size: size(mdlg);
      font-weight: 600;
      color: color(dark-color);

      &.padded {
        padding-bottom: 15px;
      }
    }

    // DESCRIPTION
    .description {
      font-family: font(main);
      font-size: size(xs);
      font-weight: 400;
      // line-height: 26px;
      white-space: pre-wrap;
      /* css-3 */
      white-space: -moz-pre-wrap;
      /* Mozilla, since 1999 */
      white-space: -pre-wrap;
      /* Opera 4-6 */
      white-space: -o-pre-wrap;
      /* Opera 7 */
      word-wrap: break-word;
      color: color(dark-color);
    }
  }

  // ACTION
  .text-block-action-container {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// --MEDIA QUERIES
@media screen and (max-width: 1100px) {

  // .text-block-container {
  //   position: relative;

  //   .text-block {

  //     .header {
  //       font-size: size(lg);
  //       line-height: 45px;
  //     }
  //   }
  // }
}

@media screen and (max-width: 820px) {
  // .content-section {}
}

@media screen and (max-width: 650px) {
  // .content-section {}
}

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphonese) {

  .content-section {
    padding: 10px;

    .image-block {
      margin-bottom: 50px;
    }
  }

  // IPHONE: TEXT BLOCK
  .text-block-container {
    .text-block {
      &.has-action {
        width: 75%;
      }

      // HEADER
      .header {
        font-size: size(lg);
        line-height: 45px;
      }

      // SUBHEADER
      .subheader {
        font-size: size(md);

        &.padded {
          padding-bottom: 15px;
        }
      }
    }

    // ACTION
    .text-block-action-container {
      top: -6px;
      right: -10px;

      .button {
        padding-right: 0;
        margin-right: 0;
        font-size: size(xxxs);
        font-weight: 600;

        svg {
          height: 15px !important;
          width: 15px !important;
          margin-left: 5px !important;
        }
      }
    }
  }
}

// IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {}