@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// CONTENT SECTION
.content-section {
  @include flex($align: center);
  // padding: 60px 200px;
  // max-width: 2000px;
  // max-width: $content-section-max-width;
  // margin: 0 auto 30px auto;
  // padding: $content-section-padding;
  // max-width: min(100%, 1600px);
  // gap: 2vw;

  .image-block {
    width: 45%;

    .content-image {
      @include backgroundSettings();
      width: 100%;
      max-height: 600px;
      // min-height: 450px;
      min-height: 400px;

      &.round-corners {
        border-radius: radius(roundest);
      }
    }
  }

  .details-block {
    width: 55%;
    // padding-left: 40px;

    // DESCRIPTION
    .text-block {
      .header {
        line-height: 1.4;
      }

      .description {
        font-family: font(alt);
        font-size: size(md);
        // line-height: 35px;
        // line-height: 35px;
        // opacity: 0.8;
        // line-height: 1.6;
        color: color(mid-grey-color) !important;
        margin: unset;
      }
    }

    // TEXT 0NLY
    &.text-only {
      width: 100%;
      // padding-left: 0;

      // FLOATING CARD
      &.floating-card {
        // padding: 140px !important;
        padding: 0 clamp(15px, 10vw, 10%);

        &.round-corners {
          border-radius: radius(roundest);
        }

        &.card-background-image {
          @include flex($direction: column, $align: unset, $justify: center);
          @include backgroundSettings();
          min-height: 500px;
        }

        .text-block {

          .header,
          .subheader {
            text-shadow: 0px 5px 15px #00000026;
            opacity: 1;
          }

          .header {
            font-size: size(xl);
            text-shadow: 1px 1px 15px #00000036;
          }

          .description {
            // width: 80%;
            font-size: size(mdlg);
            // text-shadow: 1px 1px 15px #0000001f;
            text-shadow: 1px 1px 10px #00000073;
            // line-height: 30px;
            line-height: 1.6;
            opacity: 1;

            color: color(white-color) !important;
            text-align: left;
            text-wrap: balance;
          }
        }
      }
    }

    // ACTIONS
    .actions {
      @include flex($direction: row, $justify: flex-start, $wrap: wrap);
      gap: clamp(10px, 2vw, 20px);
      margin-top: 30px;

      &.centered {
        justify-content: center;
      }

      button {
        // &:nth-child(2) {
        //   margin-left: 10px;
        //   padding: 10px 25px;
        // }

        &.round-corners {
          border-radius: radius(circle) !important;
          padding: 10px 30px;
        }
      }
    }
  }

  // REVERSED STATE
  &.reverse-content {
    flex-direction: row-reverse;

    .details-block {
      padding-left: 0;
      padding-right: 40px;

      // TEXT ONLY
      &.text-only {
        padding-right: 0;
      }

      .actions {
        justify-content: flex-end;
      }
    }
  }

  // FULL WIDTH
  &.full-width {
    flex-direction: column;

    .image-block {
      width: 100%;

      .content-image {
        min-height: 450px;
        border-radius: unset;
      }
    }

    .details-block {
      padding-left: 0;

      .text-block {
        .header {
          padding-top: 40px;
        }
      }
    }

    &.unpadded {
      padding: 60px 0;
      padding-top: 0;

      .image-block {
        .image {
          border-radius: 0 !important;
        }

        .content-image {
          border-radius: 0px !important;
        }
      }

      .details-block {
        // padding: 0 200px;
      }

      // FULL-WIDTH, UNPADDED, REVERSED
      &.reverse-content {
        .details-block {
          padding: 0 200px;
        }
      }
    }

    // FULL-WIDTH, REVERSED
    &.reverse-content {
      .details-block {
        padding-right: 0;
      }
    }
  }

  // ABSOLUTE BACKGROUND
  &.has-decoration {
    position: relative;
    z-index: 1;

    .decoration {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 50%;
      width: 100dvw;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateX(-50%);
    }
  }
}

// --MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphonese) {

  // MOBILE: CONTENT SECTION
  .content-section {
    flex-direction: column;
    // padding: 60px 20px;

    .image-block,
    .details-block {
      width: 100%;
      padding: 0;
    }

    .image-block {
      .content-image {
        min-height: 300px;
      }
    }

    .details-block {
      .text-block {
        .header {
          padding: 20px 0;
        }
      }

      // MOBILE: TEXT ONLY, FLOATING CARD
      &.text-only {
        &.floating-card {
          padding: 20px !important;

          .text-block {
            .header {
              font-size: size(lg);
              padding-bottom: 20px;
            }
          }
        }
      }

      .actions {
        // flex-direction: column;
        // align-items: flex-start;

        // .button {
        //   &:nth-child(2) {
        //     margin-left: 0;
        //     margin-top: 10px;
        //   }
        // }

        &.centered {
          align-items: center;
        }
      }
    }

    // MOBILE: REVERSED STATE
    &.reverse-content {
      flex-direction: column;

      .details-block {
        padding-right: 0px;

        .actions {
          align-items: flex-end;

          .button {
            &.round-corners {
              padding: 7px 25px !important;
            }
          }
        }

        // MOBILE: TEXT ONLY, FLOATING CARD, REVERSED
        &.text-only {
          &.floating-card {
            padding: 20px !important;

            .actions {
              align-items: flex-end;
            }
          }
        }
      }
    }

    &.full-width {
      &.unpadded {
        .details-block {
          padding: 0 30px;
        }

        // MOBILE: FULL-WIDTH, UNPADDED, REVERSED
        &.reverse-content {
          .details-block {
            padding: 0 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .responsive-content-section {
    padding: 0 5vw 60px 5vw;
  }
}

@media screen and (max-width: 820px) {
  .responsive-content-section.has-shadow {
    padding: 20px;
  }
}

@media screen and (max-width: 650px) {
  .responsive-content-section {
    padding: 0 20px 60px 20px;
  }
}

// IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {}