@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// DOT INDICATOR
.dot-indicator {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  right: -15px;
  border-radius: radius(circle);
}

// OVERRIDES
// BUTTON
.button,
button.button {
  .dot-indicator {
    @extend .dot-indicator;
    width: 12px;
    height: 12px;
    position: absolute;
    top: -4px;
    right: -6px;
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {
  // SCROLL BAR
  ::-webkit-scrollbar {
    display: none;
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}
