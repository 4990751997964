@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// TOP NAV
.top-nav-container {
  // MAIN NAV
  .top-nav {
    @include flex();
    height: container(top-nav);
    width: 100%;
    padding: 10px 25px;
    // box-shadow: 0px 10px 30px -15px #0000001c;
    border-bottom: 2px solid color(light-grey-color);

    // LOGO BLOCK
    .logo-block {
      // background: lightblue;
      @include flex($justify: flex-start);
      width: 30%;

      .return-action-block {
        @include flex();

        &.has-background {
          padding: 5px 17px 5px 5px;
          border-radius: radius(round) !important;
        }

        &.icon-only {
          @extend .has-background;
          padding: 5px !important;
        }

        .return-action-text {
          font-family: font(main);
          font-size: size(xxs);
          font-weight: 500;
        }
      }

      .header-title {
        font-family: font(main);
        font-size: size(sm);
        font-weight: 700;
        margin-left: 15px;
      }

      .icon {
        .image {
          width: 40px;
        }
      }
    }

    // MENU ITEMS BLOCK
    .menu-items-block {
      width: 40%;

      .menu-items {
        @include flex($justify: center);
        // @include flex($justify: flex-start);

        .menu-item {
          min-width: unset;
          min-height: unset;
          padding: 7px 17px;
          margin-right: 5px;

          &:hover {
            background: color(lighter-blue-color);
          }
        }
      }
    }

    // ACTIONS BLOCK
    .actions-block {
      // background: lavender;
      @include flex($justify: flex-end);
      width: 30%;

      .action-icons {
        .action-icon {
          width: 38px;
          height: 38px;
          border-radius: radius(circle) !important;
          margin-right: 7px;
          transition: 150ms ease 0s;

          &:hover {
            background: color(lighter-blue-color);
          }
        }
      }
    }
  }

  // USER AVATAR
  .user-avatar {
    @include flex($justify: center);
    color: color(white-color);
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: radius(circle) !important;
    border: 2px solid color(blue-color);

    .user-initials {
      font-family: font(main);
      font-size: size(xxxs);
      font-weight: 500;
    }
  }

  // MOBILE NAV
  .mobile-nav {
    // @extend .top-nav;
    display: none;
    // background: pink;
    height: container(top-nav);
    padding: 0 20px !important;
    border-bottom: 2px solid color(light-grey-color);

    .logo-block {
      width: 30%;
      // background: lavender;

      .icon {
        .image {
          width: 40px;
        }
      }
    }

    .actions-block {
      width: 70%;
      // background: pink;
      @include flex($justify: flex-end);

      .action-icons {
        @include flex();

        .user-avatar {
          @extend .user-avatar;
          margin-right: 10px;
        }
        .action-icon {
          background: none !important;
        }
      }
    }
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {
  .top-nav-container {
    .top-nav {
      display: none;
    }

    .mobile-nav {
      @include flex();
    }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}
