@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// MEMBER LISTING
.listing-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 80px 20px;
  padding: 40px 200px;

  .listing-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: 300px;
    padding: 0;
    line-height: 24px !important;

    &__image {
      width: 200px;
      height: 250px;
      border-radius: 10px;
      margin-bottom: 20px;
    }

    &__title {
      font-weight: 700;
      color: color(primary-color);
      font-size: size(mdlg);
    }

    &__position {
      font-size: size(sm);
      font-style: normal;
      color: color(mid-grey-color);
    }

    &__email {
      color: color(primary-lighter-color);
      font-size: size(xs);
    }

    &__link {
      // font-size: size(xs);
      // color: color(secondary-color);
      // max-width: 80%;
      font-size: size(sm);
      font-style: normal;
      color: color(mid-grey-color);
      text-align: center;
    }

    &__text {
      // line-height: 24px !important;
    }

    .text-block-action-container {
      margin-top: auto;
    }
  }

  .additional-info {
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    margin-bottom: 80px;

    &__bio {
      // Uncomment and adjust the styles below if needed.
      // padding: 20px;
      // border: 2px solid pink;
      text-align: justify;
    }
  }
}

// --MEDIA QUERIES

@media only screen and (max-width: 568px) {
  .listing-content {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

// DESKTOP (1000-1500)
@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .listing-content {
    .listing-item {
      max-width: 250px;
    }
  }
}

// TABLET (800-1000)
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .listing-content {
    .listing-item {
      width: 200px;
    }
  }
}

// MOBILE (0-800)
@media only screen and (max-width: 800px) {
  .listing-content {
    padding: 30px;
    .listing-item {
      width: 100%;
    }
  }
}

// OTHER DEVICES
@include media-query-for(others) {
}

// LAPTOP
@include media-query-for(laptop) {
}

// IPAD PRO PORTRAIT
@include media-query-for(ipadpro) {
}

// IPHONE SE
@include media-query-for(iphonese) {
}

// IPHONE X
@include media-query-for(iphonex) {
}

// IPAD
@include media-query-for(ipad) {
}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {
}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {
}
