@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// TEXT EDITOR CONTENT SECTION
.custom-content-section {
  // padding: 40px 200px;

  // max-width: $content-section-max-width;
  // margin: 0 auto;
  // padding: $content-section-padding;
  // padding-bottom: 6%;

  // THEME DEFAULTS
  .primary-text-color {
    color: color(primary-color);
  }

  .secondary-text-color {
    color: color(primary-color);
  }

  // TYPOGRAPHY TYPE ELEMENTS
  h1 {
    @extend .primary-text-color;
    font-family: font(web);
    font-size: size(xl);
    font-weight: 700;
    line-height: inherit !important;
    // padding-bottom: 10px;
  }

  h2 {
    @extend .primary-text-color;
    font-family: font(web);
    font-size: size(mdlg);
    font-weight: 600;
    line-height: inherit !important;
    // margin-bottom: 10px;
  }

  h3 {
    @extend .primary-text-color;
    font-family: font(web);
    font-size: size(md);
    font-weight: 600;
    line-height: inherit !important;
  }

  // ALIGNMENT
  h1,
  h2,
  h3 {
    &.ql-align {
      &-center {
        text-align: center;
      }

      &-right {
        text-align: right;
      }

      &-justify {
        text-align: justify;
      }
    }
  }

  p {
    @extend .secondary-text-color;
    font-family: font(alt);
    font-size: size(md);
    line-height: 1.6;
    color: color(mid-grey-color);
    font-weight: 400;

    strong {
      @extend .primary-text-color;
      font-weight: 500;
      // opacity: 0.9;
      line-height: inherit !important;
    }

    em {
      line-height: inherit !important;
    }

    // ALIGNMENT
    &.ql-align {
      &-center {
        text-align: center;
      }

      &-right {
        text-align: right;
      }

      &-justify {
        text-align: justify;
      }
    }
  }

  .ql-indent {
    &-1 {
      margin-left: 2%;
    }
  
    &-2 {
      margin-left: 4%;
    }
  
    &-3 {
      margin-left: 6%;
    }

    &-4 {
      margin-left: 8%;
    }

    &-5 {
      margin-left: 10%;
    }
  }

  // SMALL TEXT
  .ql-size-small {
    @extend .primary-text-color;
    font-family: font(web);
    font-size: size(xxs);
    // font-weight: 400;
    // opacity: 0.8;
  }

  strong.ql-size-small {
    font-weight: 700;
  }

  // LARGE TEXT
  .ql-size-large {
    @extend .primary-text-color;
    font-family: font(web);
    font-size: size(lg); 
    // font-weight: 700;
    line-height: 1.3 !important;
    // line-height: 45px;
  }

  // HUGE TEXT
  .ql-size-huge {
    @extend .primary-text-color;
    font-family: font(web);
    font-size: size(xxxl);
    // font-size: 28px;
    font-weight: 500;
    line-height: 1.2 !important;
    // line-height: 50px;

    strong {
      font-weight: 600;
    }
  }

  // p .ql-size-huge {
  //   line-height: normal;
  // }

  p {
    line-height: unset;

  }

  a {
    color: color(primary-color);
    opacity: 1 !important;
    position: relative;
    transition: color 0.3s ease;
    font-size: inherit;

    u {
      text-decoration: none;
    }
  
    &:hover {
      color: color(primary-lighter-color);
    }
  
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0.14rem !important;
      display: block;
      background: color(primary-lighter-color);
      transition: width 0.3s ease;
      bottom: 0;
      left: 0;
      transform-origin: left;
    }
  
    &:hover:after {
      width: 100%;
    }
  
    &:not(:hover):after {
      transition: width 0.3s ease, transform 0s 0.3s;
      width: 0;
      transform: translateX(100%);
    }
  }

  blockquote {
    @extend .secondary-text-color;
    font-family: font(alt);
    font-size: size(mdlg);
    line-height: 30px;
    // border-left: 7px solid color(secondary-color);
    // border-left: 7px solid color(secondary-lighter-color);
    border-left: 7px solid color(primary-lighter-color);
    padding: 5px 15px;
    margin-top: 10px;
    // border-radius: 5px;
  }

  // LIST ITEMS
  ul,
  ol {
    // padding-top: 20px;

    li {
      // @extend .secondary-text-color;
      color: color(mid-grey-color);
      font-family: font(alt);
      font-size: size(md);
      // line-height: 30px;

      strong {
        font-weight: 500;
      }
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  // IMAGES AND VIDEOS
  img {
    cursor: default !important;
    max-width: 100%;
  }

  // SVG IMAGES
  img[src*="data:image/svg+xml"] {
    // background-color: black;
  }

  iframe {
    // width: 100%;
    // height: 720px;
    width: 50%;
    height: auto;
    aspect-ratio: 600/340;
    border-radius: radius(roundest);
  }

  // iframe .ql-video {

  // }
}

// QUILL
.ql-container {
  border-radius: 0 0 6px 6px;

  .ql-toolbar {
    // transition: all 2s ease-in-out;
    border-radius: 6px 6px 0 0;
  }

  // .ql-editor iframe.ql-video src {
  //   // pointer-events: none;
  //   height: auto;
  //   width: 267px;
  //   aspect-ratio: 0.56;
  // }
}

.quill-container .ql-toolbar .ql-color-picker .ql-picker-options .ql-picker-item {
  &[data-value="primary"] {
    color: color(primary-color) !important;
    background-color: color(primary-color) !important;
  }
  &[data-value="primary-lighter"] {
    color: color(primary-lighter-color) !important;
    background-color: color(primary-lighter-color) !important;
  }
  &[data-value="primary-lightest"] {
    color: color(primary-lightest-color) !important;
    background-color: color(primary-lightest-color) !important;
  }
  &[data-value="secondary"] {
    color: color(secondary-color) !important;
    background-color: color(secondary-color) !important;
  }
  &[data-value="secondary-lighter"] {
    color: color(secondary-lighter-color) !important;
    background-color: color(secondary-lighter-color) !important;
  }
  &[data-value="secondary-lightest"] {
    color: color(secondary-lightest-color) !important;
    background-color: color(secondary-lightest-color) !important;
  }
  &[data-value="white"] {
    color: color(white-color) !important;
    background-color: color(white-color) !important;
  }
  &[data-value="light"] {
    color: color(light-color) !important;
    background-color: color(light-color) !important;
  }
  &[data-value="lighter"] {
    color: color(lighter-color) !important;
    background-color: color(lighter-color) !important;
  }
  &[data-value="lightest"] {
    color: color(lightest-color) !important;
    background-color: color(lightest-color) !important;
  }
  &[data-value="dark"] {
    color: color(dark-color) !important;
    background-color: color(dark-color) !important;
  }
  &[data-value="black"] {
    color: color(black-color) !important;
    background-color: color(black-color) !important;
  }
  &[data-value="light-grey"] {
    color: color(light-grey-color) !important;
    background-color: color(light-grey-color) !important;
  }
  &[data-value="lighter-grey"] {
    color: color(lighter-grey-color) !important;
    background-color: color(lighter-grey-color) !important;
  }
  &[data-value="lightest-grey"] {
    color: color(lightest-grey-color) !important;
    background-color: color(lightest-grey-color) !important;
  }
  &[data-value="grey"] {
    color: color(grey-color) !important;
    background-color: color(grey-color) !important;
  }
  &[data-value="mid-grey"] {
    color: color(mid-grey-color) !important;
    background-color: color(mid-grey-color) !important;
  }
  &[data-value="dark-grey"] {
    color: color(dark-grey-color) !important;
    background-color: color(dark-grey-color) !important;
  }
  &[data-value="success"] {
    color: color(success-color) !important;
    background-color: color(success-color) !important;
  }
  &[data-value="danger"] {
    color: color(danger-color) !important;
    background-color: color(danger-color) !important;
  }
  &[data-value="info"] {
    color: color(info-color) !important;
    background-color: color(info-color) !important;
  }
  &[data-value="warning"] {
    color: color(warning-color) !important;
    background-color: color(warning-color) !important;
  }
}

// --MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphonese) {
  .custom-content-section {
    // padding: 60px 20px;

    // TYPOGRAPHY TYPE ELEMENTS
    h1 {
      @extend .primary-text-color;
      font-family: font(web);
      font-size: size(lg);
      font-weight: 700;
      padding-bottom: 10px;
    }

    h2 {
      @extend .primary-text-color;
      font-family: font(web);
      font-size: size(mdlg);
      font-weight: 600;
      // padding-bottom: 5px;
    }

    p {
      @extend .secondary-text-color;
      font-family: font(alt);
      font-size: size(sm);
      // line-height: 1.6 !important;
      // color: color(dark-grey-color) !important;
      // color: #676767 !important;
      // color: color(dark-color);
      font-weight: 400;
      // text-align: justify;

      // &.ql-indent-1 {
      //   text-indent: 50px;
      // }

      // SMALL TEXT
      // .ql-size-small {
      //   @extend .primary-text-color;
      //   font-family: font(web);
      //   font-size: size(xxs);
      //   font-weight: 500;
      //   opacity: 0.8;
      // }

      // LARGE TEXT
      // .ql-size-large {
      //   @extend .primary-text-color;
      //   font-family: font(web);
      //   font-size: size(xl);
      //   font-weight: 700;
      //   line-height: 45px;
      // }

      // HUGE TEXT
      // .ql-size-huge {
      //   @extend .primary-text-color;
      //   font-family: font(web);
      //   font-size: size(xxl);
      //   font-weight: 700;
      //   line-height: 50px;
      // }
    }

    // IMAGES AND VIDEOS
    img {
      width: 100%;
    }

    iframe {
      // height: 200px;
      // width: 350px;
      width: 100%;
      height: auto;
      aspect-ratio: 600/340;
    }

    ul {
      padding-top: 5px;
    }
  }
}

// IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {}