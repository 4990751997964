@use '../functions/theme' as *;

// COLORS
$items:
  // THEME
  "primary",
  "primary-lighter",
  "primary-lightest",
  "secondary",
  "secondary-lighter",
  "secondary-lightest",
  "alt",
  // BASIC
  "white",
  "black",
  "light",
  "lighter",
  "dark",
  // GREY
  "grey",
  "light-grey",
  "lighter-grey",
  "mid-grey",
  "dark-grey",
  // STATE
  "success",
  "danger",
  "info",
  "warning",
  // EXTRAS
  "lighter-green",
  "light-red",
  "lighter-red",
  "blue",
  "light-blue",
  "lighter-blue",
  "dark-blue",
  "darker-blue",
  "yellow",
  "light-yellow",
  "lighter-yellow",
  "orange",
  "light-orange",
  // SOCIAL MEDIA
  "facebook",
  "twitter",
  "instagram",
  "linkedin",
  // CUSTOM
  "teal",
  "light-teal",
  "lighter-teal",
  "dark-green",
  "darker-green",
  // BRANDS
  "ms-excel",
  "adobe-pdf";

@each $item in $items {
  .#{$item} {
    &--clr {
      color: color(#{$item}-color) !important;
    }

    &--bg {
      background-color: color(#{$item}-color) !important;

      &:hover {
        background-color: color(#{$item}-color) !important;
        opacity: 0.95;
      }
    }

    &--sbg {
      background-color: color(#{$item}-color) !important;
    }

    &--brd {
      border: 3px solid color(#{$item}-color) !important;
    }

    // APPLIES BACKGROUND COLOR ON HOVER
    &--hover-bg {
      &:hover {
        background: color(#{$item}-color) !important;
      }
    }

    // APPLIES COLOR CLASS ON HOVER
    &--hover-clr {
      &:hover {
        color: color(#{$item}-color) !important;
      }
    }

    // APPLIES ANIMATED UNDERLINE ON HOVER
    &--hover-underline {
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: color(#{$item}-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }

    &--hover-underline-3 {
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        border-radius: 300px;
        bottom: 0;
        left: 0;
        background-color: color(#{$item}-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}
