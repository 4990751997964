@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// MOBILE HEADER
.mobile-header {
  display: none;
  width: 100%;
  height: 80px;
  padding: 20px;

  &.with-shadow {
    box-shadow: 0px 10px 30px -15px #0000000f;
  }

  .left-item-block {
    @include flex($justify: flex-start);
    // background-color: pink;
  }

  .right-item-block {
    @include flex($justify: flex-end);
    // background-color: coral;
  }

  .left-item-block,
  .right-item-block {
    width: 50%;
  }

  .image {
    width: 100%;
    height: 50px;
  }
}

// --MEDIA QUERIES

//OTHER DEVICES
@include media-query-for(others) {
}

// LAPTOP
@include media-query-for(laptop) {
}

//IPAD PRO
@include media-query-for(ipadpro) {
}

//IPHONE SE
@include media-query-for(iphonese) {
  // MOBILE HEADER
  .mobile-header {
    @include flex();
  }
}

//IPHONE X
@include media-query-for(iphonex) {
}

// IPAD PORTRAIT
@include media-query-for(ipad) {
}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {
}
