@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// CHECKBOX BLOCK
.checkbox-block-container {
  margin-bottom: 5px;

  .checkbox-label {
    font-size: size(xxs);
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
  }
}

input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

input[type="checkbox"]:hover + label::before {
  background: color(secondary-color) !important;
  transition: all 200ms ease-out 0s;
}

input[type="checkbox"] + label:before {
  width: 20px;
  height: 20px;
  display: inline-block;
  content: "\00a0";
  font-size: size(xxxxs);
  vertical-align: center;
  line-height: 15px;
  border-radius: radius(round);
  border: 3px solid color(secondary-color);
  margin-right: 10px;
}

input[type="checkbox"]:checked + label:before {
  color: color(white-color) !important;
  background: color(secondary-color);
  // content: "\2714";
  //   content: "\002713";
  content: "\2714";
  font-weight: bold !important;
  text-align: center;
  border-color: color(secondary-color);
  transition: all 200ms ease-out 0s;
}

// input[type="checkbox"]:checked+label:after {
//     font-weight: bold;
// }

input[type="checkbox"]:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {
  
  input[type="checkbox"]:checked + label:before {
    color: color(white-color) !important;
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}
