// FLEXBOX 
@mixin flex($display: flex, $justify: space-between, $align: center,  $direction: row, $wrap: nowrap) {
  display: $display;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

// BACKGROUND IMAGE
@mixin backgroundSettings($position: center, $size: cover, $repeat: no-repeat, $attachment: scroll) {
  background-position: $position !important;
  background-size: $size !important;
  background-repeat: $repeat !important;
  background-attachment: $attachment !important;
}

// BUTTON HOVER
// @mixin setBackgroundAndHoverColor($color) {
//   background: $color !important;
//   &:hover {
//       background: lighten($color, 5%) !important;
//   }
// }

// DRAWER
@mixin setDrawerWidth($width) {
  width: $width !important;
  transform: translate3d($width - $width, 0, 0) !important;
}

// BOTTOM SHEET 
@mixin setBottomSheetHeight($height) {
  height: $height;
  // transform: translate3d(0, 100 - $height, 0) !important;
  transform: translate3d(0, 100 - $height, 0) !important;
}

// MEDIA QUERIES
@mixin media-query-for($media) {
  @if $media == iphoneSE {
      @media only screen and (min-width : 320px) and (max-width : 568px) {
          @content; 
      }
  }
  @if $media == iphoneSELandscape {
      @media only screen and (min-width : 320px) and (max-width : 568px) and (orientation: landscape) {
          @content; 
      }
  }
  @else if $media == iphoneSix {
      @media only screen and (min-width : 375px) and (max-width : 667px) {
          @content;
      }
  }
  @else if $media == iphoneX {
      @media only screen and (min-width : 375px) and (max-width : 812px) {
          @content;
      }
  }
  @else if $media == iphoneXPlus {
      @media only screen and (min-width : 414px) and (max-width : 736px) {
          @content;
      }
  }
  @else if $media == ipad {
      @media only screen and (min-width : 768px) and (max-width : 1024px) {
          @content;
      }
  }
  @else if $media == ipadLandscape {
      @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation: landscape) {
          @content;
      }
  }
  @else if $media == ipadpro {
      @media only screen and (min-width : 1024px) and (max-width : 1366px) and (orientation: portrait) {
          @content;
      }
  }
  @else if $media == ipadProLandscape {
      @media only screen and (min-width : 1024px) and (max-width : 1366px) and (orientation: landscape) {
          @content;
      }
  }
  @else if $media == others {
      @media only screen and (min-width : 1200px) {
          @content;
      }
  }
  @else if $media == laptop {
      @media only screen and (min-width: 1200px) and (max-height: 800px) {
          @content;
      }
  }
  @else if $media == laptopRetina {
      @media only screen and (max-width: 2560px) {
          @content;
      }
  }
  @else if $media == smallLaptops {
    @media only screen and (min-height: 500px) and (max-height: 560px) {
        @content;
    }
}
}