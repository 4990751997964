@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// STATIC BANNER
.static-banner {
  @include flex($justify: flex-start);
  // @include backgroundSettings();
  min-height: 120vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: transparent !important;

  // DYNAMIC BANNER
  &.dynamic {
    min-height: calc(18rem + 5vw);
    background-position: left;
    background-size: cover;

    .content-section {
      margin-bottom: initial;
    }

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }

  .text-block {
    .header {
      font-size: size(xl);
      padding-bottom: unset !important;
      line-height: normal !important;
    }
  }

  .text-block {

    .header,
    .subheader {
      text-shadow: 0px 5px 20px #00000017;
      opacity: 1;
    }

    .header {
      font-size: size(xl);
      text-align: center;
    }

    .description {
      font-size: size(md) !important;
      // padding: 0 100px;
      text-shadow: 1px 1px 10px #0000002e;
      line-height: 30px;
      opacity: 1 !important;
    }
  }
}

// SLIDER BANNER
.slider-banner {
  .carousel-caption {
    position: absolute;
    right: 15%;
    top: 30%;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }
}

// --MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

//IPAD PRO
@include media-query-for(ipadpro) {}

//IPHONE SE
@include media-query-for(iphonese) {

  // STATIC BANNER
  // .static-banner {
  //   min-height: 120vh;

  //   .content-section {
  //     margin-bottom: 250px;
  //     margin-top: 80px;
  //   }

  //   // DYNAMIC BANNER
  //   &.dynamic {
  //     min-height: 300px;
  //   }

  //   .text-block {
  //     .header {
  //       font-size: size(xl);
  //     }
  //   }

  //   .text-block {

  //     .header,
  //     .subheader {
  //       text-shadow: 0px 5px 20px #0000000a;
  //     }

  //     .header {
  //       font-size: size(mdlg);
  //       line-height: 40px;
  //     }

  //     .description {
  //       font-size: size(sm) !important;
  //       padding: 0;
  //     }
  //   }
  // }

  // SLIDER BANNER
  // .slider-banner {
  //   .carousel-caption {
  //     position: absolute;
  //     right: 15%;
  //     top: 30%;
  //     left: 15%;
  //     padding-top: 1.25rem;
  //     padding-bottom: 1.25rem;
  //     color: #fff;
  //     text-align: center;
  //   }
  // }

  // .content-section {
  //   margin-bottom: 0px !important;
  // }
}

//IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {}