@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// SIDE NAV
.side-nav-wrapper {
  display: flex;

  .side-nav {
    max-height: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
    padding-top: 30px;
    overflow-y: auto;
    border-right: 1px solid color(light-grey-color);
    // padding-bottom: calc(#{container(top-nav) * 2});
    padding-bottom: calc(container(top-nav) * 2);
    transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    &.collapsible {
      overflow-y: visible;
    }

    &.expanded {
      width: container(side-nav);
    }

    &.collapsed {
      width: 85px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: color(white-color) !important;
      background: transparent;
      position: absolute;
    }

    &::-webkit-scrollbar-thumb {
      background-color: color(grey-color);
      border-radius: radius(min);
    }
  }
}

// COLLAPSE TOGGLE
.resize-control {
  width: 30px;
  position: relative;
  border-left: 7px solid color(light-grey-color);
  // background: color(light-grey-color);
  background: transparent;
  transition: transition(alt);

  &:hover {
    border-left: 7px solid color(lighter-blue-color);
    background: color(lighter-blue-color);

    .resize-icon-container {
      visibility: visible;
      opacity: 1;
    }
  }

  .resize-icon-container {
    @include flex($justify: center);
    z-index: index(3);
    position: absolute;
    width: 32px;
    height: 32px;
    top: 12px;
    right: 11px;
    background: color(white-color);
    border-radius: radius(circle);
    box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 2px, rgb(9 30 66 / 8%) 0px 2px 4px 2px;
    transition: transition(alt);
    visibility: hidden;
    opacity: 0;

    &.collapsed {
      visibility: visible;
      opacity: 1;
      transform: rotate(180deg);
    }

    &:hover {
      background: color(info-color);

      .resize-icon {
        color: color(white-color);
      }
    }

    .resize-icon {
      color: color(grey-color);
    }
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {
  .side-nav-wrapper {
    display: none;
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}
