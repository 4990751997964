@use '../helpers/mixins' as *;
@use '../functions/theme' as *;

// CONTAINER WRAPPER
.container-wrapper {
  width: 100%;
  margin-left: 100px;
  overflow: hidden;
}

// CMS CONTENT WRAPPER
.content-wrapper {
  // padding: 40px 80px;
}

// CMS CONTAINER
.cms-container {
  flex: 1 1;
  min-height: 100vh;
  // padding-top: 80px;
}

// MAIN CONTAINER
.main-container {
  flex: 1 1;
  min-height: 100vh;
}

// CONTENT CONTAINER
.content-container {
  // height: 100%;
  width: 100%;

  &--card-style {
    @extend .content-container;
    background: color(white-color);
    border-radius: radius(roundest);
    padding: 30px;

    &--with-shadow {
      @extend .content-container--card-style;
      // box-shadow: 0px 10px 30px -15px #00000026;
      // box-shadow: 0px 10px 30px -5px #0000000f;
      box-shadow: 0 6.4px 14.4px 0 #00000021, 0 1.2px 3.6px 0 #0000001c;
    }

    &--minimal {
      @extend .content-container--card-style;
      padding: 20px;
    }
  }

  &--actions {
    @extend .content-container;
    @include flex($justify: flex-end);
    height: initial;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 30px;

    .button {
      margin-left: 10px;
    }
  }

  &--padded {
    @extend .content-container;
    // padding: 65px 80px;
    padding: 30px 0px 60px 0px;
  }

  &--flex {
    @extend .content-container;
    display: flex;
  }

  &--table-container {
    @extend .content-container;
    background: color(light-color);
    border-radius: radius(roundest);
    padding: 20px;
    margin-top: 30px;
  }
}

// FULLSCREEN
.full-screen {
  width: 100%;
  height: 100vh;

  &--center-items {
    @extend .full-screen;
    @include flex($justify: center);
  }

  &--90vh {
    @extend .full-screen;
    height: 90vh;
  }
}

// FLEX CONTAINERS
.flex {
  display: flex;

  &--between {
    @extend .flex;
    justify-content: space-between;
  }

  &--around {
    @extend .flex;
    justify-content: space-around;
  }

  &--reverse {
    @extend .flex;
    flex-direction: row-reverse;
  }

  &.align-center {
    @extend .flex;
    align-items: center !important;
  }
}

// CONTAINER POSITION
.flex-center {
  @include flex($justify: center !important);
}

.flex-start {
  @include flex($justify: flex-start);
}

.flex-start-col {
  @include flex($direction: column !important, $align: flex-start !important);
}

.wrap {
  flex-wrap: wrap;
}

.rwrap {
  flex-wrap: wrap-reverse;
}

// CONTENT PADDING
.content-padding {
  padding-top: 65px;
  padding-bottom: 65px;

  &--with-banner {
    @extend .content-padding;
    padding-top: 0;
  }
}

.content-section-padding {
  padding: 60px 20px;
}

.col-section-padding {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

// TILE
.tile {
  min-height: 260px;
}

// WIDTH
.fit-content {
  width: fit-content !important;
  min-width: fit-content !important;
}

$widths: 25, 40, 50, 100;

@each $width in $widths {
  .w-#{$width} {
    width: #{$width}+'%' !important;
  }
}

// SIZE
.small {
  max-width: 870px;
}

.x-small {
  max-width: 580px;
}

// PAGE WRAPPER
.page-wrapper {
overflow-x: hidden;
  // HEADER - DESKTOP
  .header-navigation {
    max-width: $website-max-width;
    margin-inline: auto;
  }

  // MAIN CONTENT
  .main-content {
    position: relative;
    @include flex($direction: column, $justify: flex-start);
    gap: clamp(80px, 10vw, 140px) 0;
    width: $website-max-width;
    margin: clamp(80px, 10vw, 120px) auto clamp(120px, 12vw, 140px) auto;
    font-family: font(web);
    min-height: 50vh;

    .custom-content-section,
    .content-section,
    .cms-container {
      width: min(90%, 1500px);

      &.full-width {
        width: 100%;

        .content {
          width: min(90%, 1500px);
          margin: 0 auto;
        }
      }
    }

    
    // CONTENT SECTION - GENERAL
    .content-section {
      justify-content: flex-start;
      // flex-wrap: wrap;
      // column-gap: 2rem;
    
      // .image-block {
      //   margin-bottom: 2rem;
    
      //   img {
      //     width: 100%;
      //     height: auto;
      //     object-fit: cover;
      //   }
      // }
    }
  }
  
  .footer-block {
    .main-footer {
      max-width: $website-max-width;
      margin-inline: auto;
    }
  }
}

// CUSTOM CONTENT SECTION - QUILL TEXT EDITOR
.content-section.custom {
    .primary-text-color {
      color: color(primary-color);
    }
  
    .secondary-text-color {
      color: color(primary-color);
    }
  
    h1 {
      @extend .primary-text-color;
      font-family: font(web);
      font-size: size(xl);
      font-weight: 700;
      line-height: inherit !important;
    }
  
    h2 {
      @extend .primary-text-color;
      font-family: font(web);
      font-size: size(mdlg);
      font-weight: 600;
      line-height: inherit !important;
    }
  
    h3 {
      @extend .primary-text-color;
      font-family: font(web);
      font-size: size(md);
      font-weight: 600;
      line-height: inherit !important;
    }
  
    h1,
    h2,
    h3 {
      &.ql-align {
        &-center {
          text-align: center;
        }
  
        &-right {
          text-align: right;
        }
  
        &-justify {
          text-align: justify;
        }
      }
    }
  
    p {
      @extend .secondary-text-color;
      font-family: font(alt);
      font-size: size(md);
      line-height: 1.6;
      color: color(mid-grey-color);
      font-weight: 400;
  
      strong {
        @extend .primary-text-color;
        font-weight: 500;
        line-height: inherit !important;
      }
  
      em {
        line-height: inherit !important;
      }
  
      &.ql-align {
        &-center {
          text-align: center;
        }
  
        &-right {
          text-align: right;
        }
  
        &-justify {
          text-align: justify;
        }
      }
    }
  
    .ql-indent {
      &-1 {
        margin-left: 2%;
      }
    
      &-2 {
        margin-left: 4%;
      }
    
      &-3 {
        margin-left: 6%;
      }
  
      &-4 {
        margin-left: 8%;
      }
  
      &-5 {
        margin-left: 10%;
      }
    }
  
    .ql-size-small {
      @extend .primary-text-color;
      font-family: font(web);
      font-size: size(xxs);
    }
  
    strong.ql-size-small {
      font-weight: 700;
    }
  
    .ql-size-large {
      @extend .primary-text-color;
      font-family: font(web);
      font-size: size(lg); 
      line-height: 1.3 !important;
    }
  
    .ql-size-huge {
      @extend .primary-text-color;
      font-family: font(web);
      font-size: size(xxxl);
      font-weight: 500;
      line-height: 1.2 !important;
  
      strong {
        font-weight: 600;
      }
    }
  
    p {
      line-height: unset;
    }
  
    a {
      color: color(primary-color);
      opacity: 1 !important;
      position: relative;
      transition: color 0.3s ease;
      font-size: inherit;
  
      u {
        text-decoration: none;
      }
    
      &:hover {
        color: color(primary-lighter-color);
      }
    
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0.14rem !important;
        display: block;
        background: color(primary-lighter-color);
        transition: width 0.3s ease;
        bottom: 0;
        left: 0;
        transform-origin: left;
      }
    
      &:hover:after {
        width: 100%;
      }
    
      &:not(:hover):after {
        transition: width 0.3s ease, transform 0s 0.3s;
        width: 0;
        transform: translateX(100%);
      }
    }
  
    blockquote {
      @extend .secondary-text-color;
      font-family: font(alt);
      font-size: size(mdlg);
      line-height: 30px;
      border-left: 7px solid color(primary-lighter-color);
      padding: 5px 15px;
      margin-top: 10px;
    }
  
    ul,
    ol {
      li {
        color: color(mid-grey-color);
        font-family: font(alt);
        font-size: size(md);
  
        strong {
          font-weight: 500;
        }
      }
    }
  
    ul {
      li {
        list-style-type: disc;
      }
    }
  
    ol {
      li {
        list-style-type: decimal;
      }
    }
  
    img {
      cursor: default !important;
      max-width: 100%;
    }
  
    iframe {
      width: 50%;
      height: auto;
      aspect-ratio: 600/340;
      border-radius: radius(roundest);
    }
  }
  
  .ql-container {
    border-radius: 0 0 6px 6px;
  
    .ql-toolbar {
      border-radius: 6px 6px 0 0;
    }
  }
  
  .quill-container .ql-toolbar .ql-color-picker .ql-picker-options .ql-picker-item {
    &[data-value="primary"] {
      color: color(primary-color) !important;
      background-color: color(primary-color) !important;
    }
    &[data-value="primary-lighter"] {
      color: color(primary-lighter-color) !important;
      background-color: color(primary-lighter-color) !important;
    }
    &[data-value="primary-lightest"] {
      color: color(primary-lightest-color) !important;
      background-color: color(primary-lightest-color) !important;
    }
    &[data-value="secondary"] {
      color: color(secondary-color) !important;
      background-color: color(secondary-color) !important;
    }
    &[data-value="secondary-lighter"] {
      color: color(secondary-lighter-color) !important;
      background-color: color(secondary-lighter-color) !important;
    }
    &[data-value="secondary-lightest"] {
      color: color(secondary-lightest-color) !important;
      background-color: color(secondary-lightest-color) !important;
    }
    &[data-value="white"] {
      color: color(white-color) !important;
      background-color: color(white-color) !important;
    }
    &[data-value="light"] {
      color: color(light-color) !important;
      background-color: color(light-color) !important;
    }
    &[data-value="lighter"] {
      color: color(lighter-color) !important;
      background-color: color(lighter-color) !important;
    }
    &[data-value="lightest"] {
      color: color(lightest-color) !important;
      background-color: color(lightest-color) !important;
    }
    &[data-value="dark"] {
      color: color(dark-color) !important;
      background-color: color(dark-color) !important;
    }
    &[data-value="black"] {
      color: color(black-color) !important;
      background-color: color(black-color) !important;
    }
    &[data-value="light-grey"] {
      color: color(light-grey-color) !important;
      background-color: color(light-grey-color) !important;
    }
    &[data-value="lighter-grey"] {
      color: color(lighter-grey-color) !important;
      background-color: color(lighter-grey-color) !important;
    }
    &[data-value="lightest-grey"] {
      color: color(lightest-grey-color) !important;
      background-color: color(lightest-grey-color) !important;
    }
    &[data-value="grey"] {
      color: color(grey-color) !important;
      background-color: color(grey-color) !important;
    }
    &[data-value="mid-grey"] {
      color: color(mid-grey-color) !important;
      background-color: color(mid-grey-color) !important;
    }
    &[data-value="dark-grey"] {
      color: color(dark-grey-color) !important;
      background-color: color(dark-grey-color) !important;
    }
    &[data-value="success"] {
      color: color(success-color) !important;
      background-color: color(success-color) !important;
    }
    &[data-value="danger"] {
      color: color(danger-color) !important;
      background-color: color(danger-color) !important;
    }
    &[data-value="info"] {
      color: color(info-color) !important;
      background-color: color(info-color) !important;
    }
    &[data-value="warning"] {
      color: color(warning-color) !important;
      background-color: color(warning-color) !important;
    }
}

// --MEDIA QUERIES

//OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {

  // MAIN CONTAINER
  .main-container {
    height: auto;
  }
}

//IPAD PRO
@include media-query-for(ipadpro) {}

//IPHONE SE
@include media-query-for(iphonese) {

  // CONTAINER WRAPPER
  .container-wrapper {
    margin-left: 0;
  }

  // CMS CONTENT WRAPPER
  .content-wrapper {
    padding: 20px;
  }

  // CMS CONTAINER
  .cms-container {
    flex: 1 1;
    min-height: 100vh;
    // padding-top: 190px;
    // padding-top: 120px;
  }

  // CONTENT CONTAINER
  .content-container {
    &--card-style {
      // margin-top: 20px;
      margin-bottom: 20px;

      &--with-shadow {
        padding: 20px;
        padding-top: 30px;
      }
    }

    &--actions {
      width: 100%;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;

      .button,
      button.button {
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }

    &--padded {
      // padding: 0px 20px;
      padding: 0px 0px;
      padding-top: 20px;
    }
  }

  // FULLSCREEN
  .full-screen {
    width: 100%;
    height: 100vh;

    &--90vh {
      height: 80vh;
    }
  }

  // WIDTH
  .width-50,
  .width-40,
  .width-25 {
    width: 100%;
  }

  // FIXED ACTIONS BLOCK
  .fixed-actions-bar {
    flex-direction: column;

    .left-action-block {
      width: 100%;
      padding: 30px;
      padding-bottom: 0;
      margin-left: 0;

      &.small-bar {
        width: 100%;
        padding: 30px;
        padding-bottom: 0;
        margin-left: 0;
        padding: 20px;
        padding-bottom: 5px;

        .input-item {
          &.action {
            margin-right: 0;
          }
        }
      }

      .input-item {
        &:first-of-type {
          margin-right: 5px;
        }

        &:nth-of-type(2) {
          margin-left: 5px;
        }

        &.action {
          width: 100%;

          .button,
          button.button {
            width: 100%;
          }
        }
      }
    }

    .content-container--actions {
      width: 100%;
      flex-direction: row;
      margin-top: 0;

      &.with-input {
        padding-top: 0;
      }

      button.button {
        &:first-of-type {
          margin-left: 0;
          margin-bottom: 0;
        }
      }
    }

    .content-container--actions.full-width {}
  }
}

//IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {

  // CONTAINER WRAPPER
  .container-wrapper {
    width: 85%;
    margin-left: 95px;
    margin-top: 0px;
    margin-bottom: 30px;
  }

  // CONTENT CONTAINER
  .content-container {
    &--card-style {
      &--with-shadow {
        padding: 20px;
      }
    }

    &--actions {
      padding-left: 0;
      padding-right: 0;
    }

    &--padded {
      padding: 20px 0px;
    }
  }

  // FULLSCREEN
  .full-screen {
    width: 100%;
    height: 100vh;
  }
}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {

  // CONTAINER WRAPPER
  .container-wrapper {
    width: 90%;
    margin-left: 90px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  // CONTENT CONTAINER
  .content-container {
    &--card-style {
      &--with-shadow {
        padding: 20px;
        padding-top: 30px;
      }
    }
  }
}

//IPHONE X PLUS
@include media-query-for(iphonexplus) {}