@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// HEADER BLOCK
.header-block {
  // background: pink;
  @include flex();
  width: 100%;
  height: 80px;
  padding: 20px 30px;

  .return-action-container {
    margin-right: 10px;

    svg {
      margin-left: -10px;
    }
  }

  .left-item-block {
    @include flex($justify: flex-start);
  }

  .right-item-block {

    .right-item-action-container {
      @include flex();

      .button {
        &:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
  }

  .item-block-container {

    p {
      font-family: font(alt);
      font-size: size(md);
      font-weight: 600;
    }

    .button {
      font-family: font(alt);
      text-transform: uppercase;
      letter-spacing: 1px;
      min-width: 100px;
      padding: 10px 20px;
    }
  }
}

.header-block.bordered {
  border-bottom: 1px solid color(lighter-grey-color);
}

.header-block.with-shadow {
  box-shadow: 0px 10px 30px -15px #0000000f;
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {

  // HEADER BLOCK
  .header-block {
    height: unset;
    min-height: 80px;
    flex-direction: column;
    align-items: unset;
    margin-bottom: 20px;

    .return-action-container {
      margin-left: -10px;
    }

    .item-block-container {

      .button {
        font-size: size(xxxxs);
        margin-top: 10px;
      }
    }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {

  // HEADER BLOCK
  .header-block {
    height: 80px;
    flex-direction: row;

    .item-block-container {

      p {
        font-size: size(sm);
      }

      .button {
        font-size: size(xxxxs);
      }
    }
  }
}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {

  // HEADER BLOCK
  .header-block {
    height: 80px;

    .item-block-container {

      p {
        font-size: size(sm);
      }

      .button {
        font-size: size(xxxxs);
      }
    }
  }
}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}