@use "../helpers/mixins" as *;
@use "../functions/theme" as *;

$padding: 30px;

.auth-page-wrapper {
  background-color: color(darker-blue-color);
  height: 100%;
  width: 100%;
  padding: 80px;

  .auth-page {
    @include flex($justify: flex-start);
    width: 100%;
    height: 100%;
    padding: $padding;
    background-color: color(white-color);
    // background-color: pink;
    border-radius: radius(roundest);

    .logo-block,
    .form-block {
      height: 100%;
    }

    .logo-block {
      @include flex($justify: center);
      width: 40%;
      position: relative;
      border-radius: radius(rounder);
      padding: $padding;
      background-color: color(light-grey-color);

      .copyright-block {
        position: absolute;
        bottom: $padding;

        p {
          color: color(mid-grey-color);
          font-weight: 400;
          font-size: size(xxxs);
        }
      }
    }

    .form-block {
      @include flex($justify: center);
      width: 60%;
      background-color: color(white-color);

      .input-elements {
        width: 450px;

        .form-action-block,
        .link-block {
          padding-left: 5px;
          padding-right: 5px;
        }

        .form-action-block {
          // margin-top: 30px;
          margin-top: 20px;

          .button {
            width: 100%;
            background-color: color(primary-color);
          }
        }

        .link-block {
          // @include flex($justify: space-between);
          @include flex($justify: flex-end);

          .text-block {
            width: fit-content;
            font-weight: 500;
            border-bottom: 2px solid color(info-color);

            &:nth-child(2) {
              color: color(info-color);
              border-bottom: 2px solid color(info-color);
              transition: transition(secondary);
            }

            &:hover {
              opacity: 0.8;
              transition: transition(secondary);
            }
          }
        }
      }

      // CUSTOM COMPONENTS
      .input-block {
        background-color: color(light-grey-color) !important;

        &::placeholder {
          color: color(grey-color);
          font-size: size(xxs);
          font-weight: 400;
        }
      }

      .icon-left-container {
        svg {
          color: color(mid-grey-color) !important;
        }
      }
    }
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {
}

// LAPTOP
@include media-query-for(laptop) {
}

// IPAD PRO
@include media-query-for(ipadpro) {
}

// IPHONE SE
@include media-query-for(iphoneSE) {
}

// IPHONE X
@include media-query-for(iphoneX) {
  .auth-page-wrapper {
    padding: 20px;
    background-color: color(white-color);

    .auth-page {
      flex-direction: column;
      padding: $padding - 20px;

      .logo-block,
      .form-block {
        width: 100%;
      }

      .logo-block {
        padding: $padding - 20px;
        height: 50%;

        .image {
          width: 250px;
        }

        .copyright-block {
          display: none;
        }
      }

      .form-block {
        .input-elements {
          width: 450px;

          .input-header {
            .content-header {
              padding-top: 15px;
              padding-bottom: 0px;
              
              .text-header {
                justify-content: center;
              }
            }
          }

          .form-action-block,
          .link-block {
            padding-left: 5px;
            padding-right: 5px;
          }

          .form-action-block {
            margin-top: 15px;

            // .button {
            //   width: 100%;
            //   background-color: color(primary-color);
            // }
          }

          // .link-block {
          // }
        }
      }
    }
  }
}

// IPAD PORTRAIT
@include media-query-for(ipad) {
}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {
}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {
}

// IPHONE X PLUS
@include media-query-for(smallLaptops) {
  .auth-page-wrapper {
    padding: 20px;

    .auth-page {
      .logo-block,
      .form-block {
        height: 100%;
      }

      .logo-block {
        @include flex($justify: center);
        width: 40%;
        position: relative;
        border-radius: radius(rounder);
        padding: $padding;
        // background-color: color(light-grey-color);
        background-color: color(primary-color);

        .image {
          width: 250px;
        }

        .copyright-block {
          p {
            font-size: size(xxxxs);
          }
        }
      }

      .form-block {
        @include flex($justify: center);
        width: 60%;
        background-color: color(white-color);

        .input-elements {
          width: 450px;

          .input-header {
            .content-header {
              padding-bottom: 20px;
            }
          }

          .form-action-block,
          .link-block {
            padding-left: 5px;
            padding-right: 5px;
          }

          .form-action-block {
            margin-top: 30px;

            .button {
              width: 100%;
              background-color: color(primary-color);
            }
          }

          .link-block {
            // @include flex($justify: space-between);
            @include flex($justify: flex-end);

            .text-block {
              width: fit-content;
              font-weight: 500;
              border-bottom: 2px solid color(info-color);

              &:nth-child(2) {
                color: color(info-color);
                border-bottom: 2px solid color(info-color);
                transition: transition(secondary);
              }

              &:hover {
                opacity: 0.8;
                transition: transition(secondary);
              }
            }
          }
        }

        // CUSTOM COMPONENTS
        .input-block {
          background-color: color(light-grey-color) !important;

          &::placeholder {
            color: color(grey-color);
            font-size: size(xxs);
            font-weight: 400;
          }
        }

        .icon-left-container {
          svg {
            color: color(mid-grey-color) !important;
          }
        }
      }
    }
  }
}
