@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// RADIO BUTTON
.radio-button-container {
  @include flex($justify: flex-start);
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;

  .radio-button {
      @include flex($justify: center);
      width: 20px;
      height: 20px;
      border-radius: radius(circle);
      margin-right: 10px;
      transition: all 150ms ease-in 0s;

      .selected-indicator {
          width: 6px;
          height: 6px;
          border-radius: radius(circle);
          background: color(white-color);
      }
  }

  .radio-button.selected {
      background: color(light-teal-color) !important;
  }

  .label {

      p {
          font-size: size(xxs);
          font-weight: 600;
          color: color(dark-color);
      }
  }
}


// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {

  // RADIO BUTTON
  .radio-button-container {

      .radio-button {
          width: 18px;
          height: 18px;
          margin-right: 10px;

          .selected-indicator {
              width: 4px;
              height: 4px;
          }
      }

      .label {

          p {
              font-size: size(xxxs);
          }
      }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {

  // RADIO BUTTON
  .radio-button-container {

      .radio-button {
          width: 15px;
          height: 15px;
          margin-right: 5px;

          .selected-indicator {
              width: 3px;
              height: 3px;
          }
      }

      .label {

          p {
              font-size: size(xxxxxs);
          }
      }
  }
}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}