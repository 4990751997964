@use "../functions/theme" as *;
@use "../helpers/mixins" as *;

// CARD BLOCK
.card-block {
  cursor: pointer;
  transition: transition(secondary);

  &:hover {
    transform: scale(1.015);
    transition: transition(secondary);
  }
}
