@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// HOMEPAGE CUSTOM BANNER INSERT
.custom-banner {
  padding: 4%;
  padding-top: 200px;
  min-height: 40vh;
}

@media screen and (max-width: 900px) {
  .custom-banner-container {
    .content-section {
      margin: 0 auto;
      padding: 20px !important;
    }
  }

  .custom-banner {
    padding-top: 120px;
  }
}

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptopretina) {}

//IPAD PRO
@include media-query-for(ipadpro) {}

//IPHONE SE
@include media-query-for(iphonese) {}

//IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {}