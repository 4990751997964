@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// PRODUCT CARD CONTAINER
.listing-container {
  @include flex($justify: center);
  width: 100%;
  margin-top: 30px;
}

.product-card-container {
  @include flex($justify: center, $wrap: wrap);
}

// PRODUCT CARD
.product-card {
  width: 300px !important;
  height: 490px !important;
  background-color: color(white-color);
  margin: 10px;
  overflow: hidden;

  &.round-corners {
    border-radius: radius(rounder);
  }

  // IMAGE BLOCK
  .image-block {
    @include backgroundSettings($size: contain);
    position: relative;
    display: inline-block;
    width: 100%;
    height: 45%;
    background: color(white-color);
    border-bottom: 1px solid color(light-grey-color);

    .badge {
      position: absolute;
      top: 12px;
      left: 12px;
      border-radius: radius(min) !important;

      .badge-text {
        font-family: font(alt);
        font-weight: 500;
        font-size: size(xxs);
      }
    }
  }

  // DETAILS BLOCK
  .details-block {
    padding: 15px;
    height: 45%;
    // background: lavender;

    .text-block {
      .product-card-title {
        height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      // PRICE
      .price-block {
        @include flex($justify: flex-start);
        .price {
          font-weight: 600;
        }

        .current-price {
          color: color(danger-color);
          font-size: size(md);
        }

        .savings-block {
          @include flex($justify: flex-start);

          .previous-price {
            color: color(grey-color);
            font-weight: 400;
            font-size: size(xxs);
            text-decoration: line-through;
            margin-left: 15px;
          }

          .discount {
            @extend .previous-price;
            text-decoration: none;
            color: color(danger-color);
            margin-left: 5px;
            opacity: 0.8;
            font-weight: 500;
          }
        }
      }
    }

    // PROMO CODE
    .promo-code-container {
      @include flex($justify: center);
      width: 100%;
      margin-top: 10px;

      .promo-code {
        color: color(dark-grey-color);
        background: color(lightest-grey-color);
        font-weight: 500;
        text-align: center;
        border: 2px dashed color(grey-color);
        border-radius: radius(round);
        padding: 7px 10px;
        overflow: hidden;

        &.copied {
          color: color(success-color) !important;
          background: color(lighter-green-color) !important;
          border: 2px dashed color(success-color) !important;
        }
      }
    }
  }

  // FEATURES BLOCK
  .features-block {
    @include flex();
    height: 10%;
    padding: 15px;
    padding-top: 0;

    .countdown-block {
      width: 70%;

      .countdown-text {
        font-weight: 500;
        font-size: size(xxxs) !important;
      }
    }

    .clickable-item {
      @include flex($justify: flex-end);
      width: 30%;

      .counter-text {
        margin-left: 5px;
        color: color(grey-color);
      }
    }
  }
}

// --MEDIA QUERIES

// LAPTOP DEVICES
@include media-query-for(laptop) {
}

// LAPTOP - RETINA
@include media-query-for(laptopretina) {
  // LAPTOP - RETINA : PRODUCT CARD
  .product-card {
    width: 310px !important;
    height: 470px !important;
  }
}

//IPAD PRO
@include media-query-for(ipadpro) {
}

//IPHONE SE
@include media-query-for(iphonese) {
  // MOBILE - RETINA : PRODUCT CARD
  .product-card {
    display: none;
  }
}

//IPHONE X
@include media-query-for(iphonex) {
}

// IPAD PORTRAIT
@include media-query-for(ipad) {
}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {
}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {
}
