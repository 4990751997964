@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 271.1862487792969px;
    stroke-dasharray: 271.1862487792969px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 271.1862487792969px;
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-1 {
  animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
    animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 271.1875305175781px;
    stroke-dasharray: 271.1875305175781px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 271.1875305175781px;
  }
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(71, 180, 148);
  }
}

.svg-elem-2 {
  animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
    animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 271.1875915527344px;
    stroke-dasharray: 271.1875915527344px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 271.1875915527344px;
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-3 {
  animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both,
    animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
}

@keyframes animate-svg-stroke-4 {
  0% {
    stroke-dashoffset: 271.1873779296875px;
    stroke-dasharray: 271.1873779296875px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 271.1873779296875px;
  }
}

@keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-4 {
  animation: animate-svg-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both,
    animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both;
}

@keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 271.18756103515625px;
    stroke-dasharray: 271.18756103515625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 271.18756103515625px;
  }
}

@keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-5 {
  animation: animate-svg-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both,
    animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both;
}

@keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 184.0016632080078px;
    stroke-dasharray: 184.0016632080078px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 184.0016632080078px;
  }
}

@keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-6 {
  animation: animate-svg-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both,
    animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both;
}

@keyframes animate-svg-stroke-7 {
  0% {
    stroke-dashoffset: 168.73452758789062px;
    stroke-dasharray: 168.73452758789062px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 168.73452758789062px;
  }
}

@keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-7 {
  animation: animate-svg-stroke-7 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both,
    animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both;
}

@keyframes animate-svg-stroke-8 {
  0% {
    stroke-dashoffset: 273.2879638671875px;
    stroke-dasharray: 273.2879638671875px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 273.2879638671875px;
  }
}

@keyframes animate-svg-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-8 {
  animation: animate-svg-stroke-8 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both,
    animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
}

@keyframes animate-svg-stroke-9 {
  0% {
    stroke-dashoffset: 268.00408935546875px;
    stroke-dasharray: 268.00408935546875px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 268.00408935546875px;
  }
}

@keyframes animate-svg-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-9 {
  animation: animate-svg-stroke-9 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both,
    animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
}

@keyframes animate-svg-stroke-10 {
  0% {
    stroke-dashoffset: 266.6200866699219px;
    stroke-dasharray: 266.6200866699219px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 266.6200866699219px;
  }
}

@keyframes animate-svg-fill-10 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-10 {
  animation: animate-svg-stroke-10 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both,
    animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
}

@keyframes animate-svg-stroke-11 {
  0% {
    stroke-dashoffset: 279.1511535644531px;
    stroke-dasharray: 279.1511535644531px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 279.1511535644531px;
  }
}

@keyframes animate-svg-fill-11 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-11 {
  animation: animate-svg-stroke-11 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both,
    animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
}

@keyframes animate-svg-stroke-12 {
  0% {
    stroke-dashoffset: 239.39019775390625px;
    stroke-dasharray: 239.39019775390625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 239.39019775390625px;
  }
}

@keyframes animate-svg-fill-12 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-12 {
  animation: animate-svg-stroke-12 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both,
    animate-svg-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
}

@keyframes animate-svg-stroke-13 {
  0% {
    stroke-dashoffset: 168.73562622070312px;
    stroke-dasharray: 168.73562622070312px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 168.73562622070312px;
  }
}

@keyframes animate-svg-fill-13 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-13 {
  animation: animate-svg-stroke-13 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both,
    animate-svg-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
}

@keyframes animate-svg-stroke-14 {
  0% {
    stroke-dashoffset: 178.31260681152344px;
    stroke-dasharray: 178.31260681152344px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 178.31260681152344px;
  }
}

@keyframes animate-svg-fill-14 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-14 {
  animation: animate-svg-stroke-14 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both,
    animate-svg-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
}

@keyframes animate-svg-stroke-15 {
  0% {
    stroke-dashoffset: 60.21845626831055px;
    stroke-dasharray: 60.21845626831055px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 60.21845626831055px;
  }
}

@keyframes animate-svg-fill-15 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-15 {
  animation: animate-svg-stroke-15 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both,
    animate-svg-fill-15 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
}

@keyframes animate-svg-stroke-16 {
  0% {
    stroke-dashoffset: 81.81548309326172px;
    stroke-dasharray: 81.81548309326172px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 81.81548309326172px;
  }
}

@keyframes animate-svg-fill-16 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-16 {
  animation: animate-svg-stroke-16 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both,
    animate-svg-fill-16 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
}

@keyframes animate-svg-stroke-17 {
  0% {
    stroke-dashoffset: 117.46885681152344px;
    stroke-dasharray: 117.46885681152344px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 117.46885681152344px;
  }
}

@keyframes animate-svg-fill-17 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-17 {
  animation: animate-svg-stroke-17 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both,
    animate-svg-fill-17 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s both;
}

@keyframes animate-svg-stroke-18 {
  0% {
    stroke-dashoffset: 27.88190460205078px;
    stroke-dasharray: 27.88190460205078px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 27.88190460205078px;
  }
}

@keyframes animate-svg-fill-18 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-18 {
  animation: animate-svg-stroke-18 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both,
    animate-svg-fill-18 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
}

@keyframes animate-svg-stroke-19 {
  0% {
    stroke-dashoffset: 48.20691680908203px;
    stroke-dasharray: 48.20691680908203px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 48.20691680908203px;
  }
}

@keyframes animate-svg-fill-19 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-19 {
  animation: animate-svg-stroke-19 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s both,
    animate-svg-fill-19 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s both;
}

@keyframes animate-svg-stroke-20 {
  0% {
    stroke-dashoffset: 93.7240982055664px;
    stroke-dasharray: 93.7240982055664px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 93.7240982055664px;
  }
}

@keyframes animate-svg-fill-20 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-20 {
  animation: animate-svg-stroke-20 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s both,
    animate-svg-fill-20 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s both;
}

@keyframes animate-svg-stroke-21 {
  0% {
    stroke-dashoffset: 108.68201446533203px;
    stroke-dasharray: 108.68201446533203px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 108.68201446533203px;
  }
}

@keyframes animate-svg-fill-21 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(8, 86, 162);
  }
}

.svg-elem-21 {
  animation: animate-svg-stroke-21 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s both,
    animate-svg-fill-21 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s both;
}