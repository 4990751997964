@use "./mixins" as *;
@use "../functions/theme" as *;

// DEFINE SPACING VALUES
$spacingValues: 0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200, 300, 400, 500 auto;

// MAP FOR PROPERTY NAMES
$propertyMap: (
  "m": "margin",
  "p": "padding",
);

// HELPER MIXIN TO GENERATE CLASSES
@mixin generate-spacing-classes($property, $values) {
  $fullPropertyName: map-get($propertyMap, $property);

  @each $value in $values {
    @if $value == auto {
      .#{$property}-auto {
        #{$fullPropertyName}: auto !important;
      }

      // INDIVIDUAL DIRECTIONS
      .#{$property}t-auto {
        #{$fullPropertyName}-top: auto !important;
      }
      .#{$property}r-auto {
        #{$fullPropertyName}-right: auto !important;
      }
      .#{$property}b-auto {
        #{$fullPropertyName}-bottom: auto !important;
      }
      .#{$property}l-auto {
        #{$fullPropertyName}-left: auto !important;
      }
      .#{$property}x-auto {
        #{$fullPropertyName}-left: auto !important;
        #{$fullPropertyName}-right: auto !important;
      }
      .#{$property}y-auto {
        #{$fullPropertyName}-top: auto !important;
        #{$fullPropertyName}-bottom: auto !important;
      }
    } @else {
      .#{$property}-#{$value} {
        #{$fullPropertyName}: #{$value}px !important;
      }

      // INDIVIDUAL DIRECTIONS
      .#{$property}t-#{$value} {
        #{$fullPropertyName}-top: #{$value}px !important;
      }
      .#{$property}r-#{$value} {
        #{$fullPropertyName}-right: #{$value}px !important;
      }
      .#{$property}b-#{$value} {
        #{$fullPropertyName}-bottom: #{$value}px !important;
      }
      .#{$property}l-#{$value} {
        #{$fullPropertyName}-left: #{$value}px !important;
      }

      // LEFT AND RIGHT (X AXIS)
      .#{$property}x-#{$value} {
        #{$fullPropertyName}-left: #{$value}px !important;
        #{$fullPropertyName}-right: #{$value}px !important;
      }

      // TOP AND BOTTOM (Y AXIS)
      .#{$property}y-#{$value} {
        #{$fullPropertyName}-top: #{$value}px !important;
        #{$fullPropertyName}-bottom: #{$value}px !important;
      }
    }
  }
}

// GENERATE MARGIN AND PADDING CLASSES
@include generate-spacing-classes("m", $spacingValues);
@include generate-spacing-classes("p", $spacingValues);

// LINE HEIGHT
.lh-0 {
  line-height: 1 !important;
}
.lh-1 {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.75 !important;
}
.lh-normal {
  line-height: normal !important;
}
.lh-sm {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}

// MISC

.top-margin-on-mobile-only {
  margin: 0 !important;
}

.bottom-margin-on-mobile-only {
  margin: 0 !important;
}

// BORDER RADIUS
.radius {
  &--chipped {
    border-radius: radius(chipped) !important;
  }

  &--round {
    border-radius: radius(round) !important;
  }

  &--rounder {
    border-radius: radius(rounder) !important;
  }

  &--roundest {
    border-radius: radius(roundest) !important;
  }

  &--circle {
    border-radius: radius(circle) !important;
  }
}

.round {
  border-radius: radius(round) !important;
}

.rounder {
  border-radius: radius(rounder) !important;
}

.roundest {
  border-radius: radius(roundest) !important;
}

.circle {
  border-radius: radius(circle) !important;
}

// DISPLAY / HIDE
.desktop-only {
  display: initial;
}

.mobile-only {
  display: none;
}

.d-block {
  display: block;
}

// TEXT TRANSFORM AND FONT STYLE
.uppercase {
  text-transform: uppercase !important;
}

.italic {
  font-style: italic !important;
}

// CURSOR
.cursor {
  &--pointer {
    cursor: pointer !important;
  }
}

// TEXT DECORATION
.text-decoration {
  &--default {
    &:hover {
      text-decoration: underline;
      color: color(info-color);
    }
  }
}

// DEFINE GAP SIZES
$gapSizes: 5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100;

// GENERAL GAP
@each $size in $gapSizes {
  .gap-#{$size} {
    gap: #{$size}px !important;
  }
}

// DEFINE LINE HEIGHT VALUES
$lineHeights: (
  0: 1,
  1: 1.25,
  sm: 1.5,
  base: 1.75,
  lg: 2,
);

// GENERATE LINE HEIGHT CLASSES
@each $name, $value in $lineHeights {
  .lh-#{$name} {
    line-height: #{$value} !important;
  }
}

// MEDIA QUERIES

// OTHERS
@include media-query-for(others) {
}

// LAPTOP
@include media-query-for(laptop) {
}

// IPAD PRO
@include media-query-for(ipadpro) {
  // DISPLAY / HIDE
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}

// IPHONE SE
@include media-query-for(iphoneSE) {
  // DISPLAY / HIDE
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  // MARGIN
  .top-margin-on-mobile-only {
    margin-top: 15px !important;
  }

  .bottom-margin-on-mobile-only {
    margin-bottom: 15px !important;
  }
}

// IPHONE X
@include media-query-for(iphoneX) {
  // DISPLAY / HIDE
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}

// IPAD
@include media-query-for(ipad) {
  // DISPLAY / HIDE
  .mobile-only {
    display: none;
  }
}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {
}
