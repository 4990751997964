@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// CONTENT HEADER
.content-header {
  @include flex($align: center);
  padding-bottom: 40px;
  margin-left: 5px;
  margin-right: 5px;

  .content-header-button-actions {
      @include flex();

      .button, button.button, .button--sm, .button--xs, .button--xxs {
          &:nth-last-child(1) {
              margin-left: 10px;
          }
      }
  }

  .content-header-icon-actions {

      a {
          color: color(mid-grey-color);
          background: color(light-grey-color);
          padding: 7px 8px;
          border-radius: radius(circle);
          transition: all 400ms ease 0s;
      }
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {

  // CONTENT HEADER
  .content-header {
      @include flex($direction: column);
      text-align: center;
      // padding-bottom: 30px;

      .text-header--lg {
          font-size: size(mdlg);
      }

      // .text-header--md.pb-15 {
      //     padding-bottom: 0 !important;
      // }

      .text-header--lg,
      .text-header--md {
          text-align: center;
          margin-top: 30px;
      }

      .content-header-button-actions {
          @include flex($direction: column);
          width: 100%;
          padding-left: -5px !important;
          padding-top: 10px;

          .button, .button--sm, .button--xs, .button--xxs {
              width: 100%;

              &:nth-last-child(1) {
                  margin-top: 10px;
                  margin-left: 0px !important;
              }
          }
      }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {

  // CONTENT HEADER
  .content-header {
     
      .text-header--lg {
          font-size: size(mdlg);
      }

      .text-header--md {
          font-size: size(sm);
      }
  }
}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}