@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// IMAGE
.image {
  width: 100%;
  height: 100%;

  &--rounded {
      @extend .image;
      border-radius: radius(rounder);
  }
}

// SMALL IMAGE
.smaller-image-container {
@include flex($justify: center);
width: 60px;
// height: 60px;
}

.small-image-container {
@include flex($justify: center);
width: 80px;
// height: 80px;
}

.medium-image-container {
@include flex($justify: center);
width: 120px;
height: 120px;
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}