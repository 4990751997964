@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

.social-media-block {
  @include flex($justify: center);
  height: 80px;
}

// SOCIAL ICON SET
.social-icon-set {
  .social-icon {
    box-shadow: 3px 3px 5px -3px rgba(0, 0, 0, 0.20);
  }
}

// SOCIAL ICON BUTTON
.social-icon {
  width: 45px;
  height: 45px;
  margin-right: 15px;
  transition: all 200ms ease 0s;
  border-radius: 300px !important;

  &.admin,
  &.contact-us {
    svg {
      filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.2));
    }
  }

  &:hover {
    transform: scale(1.2);
    transition: transform 150ms ease 0s;
  }

  &.square {
    border-radius: 0 !important;
  }

  &.round {
    border-radius: radius(roundest) !important;
  }

  &.circle {
    border-radius: radius(circle) !important;
  }
}

// SOCIAL ICON TOOLTIP
.custom-tooltip {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 10000 !important;

  .tooltip-inner {
    line-height: 0;
    padding: 12px 6px;
    background-color: color(primary-color);
    color: color(white-color);
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    font-size: size(xxxs) !important;
    font-family: font(main);
  }

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }
}