@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// CONTACT PAGE
.contact-page {
  // COMPANY LOGO SECTION
  .logo-section {
    @include flex($justify: center, $wrap: wrap);
    column-gap: 40px;
    row-gap: 60px;
    max-width: 100%;
    overflow: hidden;

    .logo-container {
      margin: auto;
      max-width: min(70%, 450px);

      .logo {
        width: 100%;
        opacity: 0.90;
      }
    }

    .image-container {
      position: relative;
      width: 100%;
      max-width: 700px;
      margin: auto;
      box-shadow: 0px 10px 30px -5px #0000000f;
      margin-bottom: 20px;

      .slick-list {
        overflow: hidden;
        border-radius: radius(roundest);
      }
    }

    .slick-dots {
      position: absolute;
      // bottom: -20px;
      bottom: -20px;
      z-index: 90;

      li {

        &:hover {
          color: color(primary-color);
        }

        button {

          &::before {
            font-size: 12px;
            color: color(mid-grey-color);
            transition: all 0.3s ease-in-out;
          }

        }
      }

      li.slick-active {
        button {
          &::before {
            color: color(primary-color);
            font-size: 16px;
          }
        }
      }
    }

    .slick-arrow::before {
      font-size: 30px;
      color: color(mid-grey-color) !important;
      display: none;
    }

    .slick-prev {
      left: -35px;
    }
  }

  // FLEX CONTAINER
  .flex-container {
    @include flex($align: flex-start, $justify: flex-start, $wrap: wrap);
    gap: 30px;
    row-gap: 80px;
    width: 100%;
  
    // GOOGLE MAP SECTION
    .google-map-section {
      flex: 2 2 300px;
  
      iframe {
        width: 100%;
        min-height: 500px;
        border-radius: 15px;
  
      }
    }
  
    // DETAILS SECTION
    .details-section {
      flex: 1 1 350px;
      padding: clamp(15px, 4vw, 30px);
      // min-height: 500px;
      min-height: 100%;
      background: color(white-color);
      border-radius: 15px;
      align-self: stretch;
      box-shadow: #32325d40 0px 3px 8px -1px;
  
      .contact {
        @include flex($direction: column, $align: flex-start, $justify: flex-start);
        row-gap: 20px;
  
        &__row {
          @include flex($align: center, $justify: flex-start);
          column-gap: 10px;
          min-height: 100%;
  
          &__icon {
            @include flex($align: center, $justify: center);
            background-image: linear-gradient(45deg, color(secondary-color) 0%, color(secondary-lighter-color) 100%);
            // box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
            padding: 0 4px;
            opacity: 0.90;
            border-radius: radius(round);
            align-self: stretch;
          }
        }
      }
    }
  
    .google-map-section {
      flex: 2 2 400px;
      border: 0;
      border-radius: 15px;
      height: 100%;
      min-height: 500px;
      box-shadow: #32325d40 0px 3px 8px -1px;
    }
  }

  // CONTACT FORM
  .form-block {
    .input-block {
      // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  
      &:focus {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
      }
    }

    @media (width < 500px) {
      .content-container--card-style {
        padding: 20px;
      }
      .input-block-container {
        margin-right: unset;
      }
    }
  }

}


@media screen and (max-width: 700px) {

  .custom-content-section {
    h2 {
      font-size: 28px;
    }

    .details-section {
      padding: 20px !important;
      min-height: fit-content !important;
    }
  }
}