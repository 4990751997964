@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// TEXT HEADER
.text-header {
  @include flex($justify: unset);
  position: relative;
  font-family: font(main);
  font-size: size(lg);
  font-weight: 700;
  color: color(primary-color);
  // padding-bottom: 30px;

  &.centered {
    text-align: center !important;
  }

  &--xxxs {
    @extend .text-header;
    font-size: size(xxxs);
    padding-bottom: 5px;
  }

  &--xxs {
    @extend .text-header;
    font-size: size(xxs);
    padding-bottom: 5px;
  }

  &--xs {
    @extend .text-header;
    font-size: size(xs);
    padding-bottom: 10px;
  }

  &--sm {
    @extend .text-header;
    font-size: size(sm);
    padding-bottom: 10px;
  }

  &--md {
    @extend .text-header;
    font-size: size(md);
  }

  &--mdlg {
    @extend .text-header;
    font-size: size(mdlg);
  }

  &--lg {
    @extend .text-header;
    font-size: size(lg);
  }

  &--xl {
    @extend .text-header;
    font-size: size(xl);
  }

  .text-header-icon-left {
    @include flex($justify: center);
    width: 50px;
    height: 50px;
    border-radius: radius(circle);
    margin-right: 10px;
  }
}

.text-header-subtitle {
  color: color(primary-color);
  font-size: size(xxs);
  font-weight: 600;
  padding-bottom: 5px;
  opacity: 0.8;

  &--sm {
    @extend .text-header-subtitle;
    font-size: size(xxs);
  }

  &--md {
    @extend .text-header-subtitle;
    font-size: size(xs);
  }

  &--lg {
    @extend .text-header-subtitle;
    font-size: size(sm);
  }

  &--xl {
    @extend .text-header-subtitle;
    font-size: size(md);
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {
  // TEXT HEADER SUBTITLE
  .text-header-subtitle {
    padding-bottom: 5px;
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}