@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// FIXED ACTIONS BAR
.fixed-actions-bar {
  @include flex();
  background: color(white-color);
  z-index: 9997;
  position: fixed;
  width: 100%;
  // bottom: 0;
  right: 0;
  box-shadow: 0px 10px 30px -5px #00000026;

  &.top {
    top: 60px;

    .left-action-block {
      width: 70%;
      padding: 10px 20px;
      padding-top: 15px;
    }

    .content-container--actions {
      width: 30%;
    }
  }

  &.bottom {
    bottom: 0;
  }

  .left-action-block {
    @include flex($justify: flex-start);
    width: 50%;
    padding: 20px;
    // padding-left: 25px;
    // margin-left: 100px;

    &.small-bar {
      // padding-left: 20px;
    }
  }

  .content-container--actions {
    width: 50%;
    padding: 30px;
    margin-top: 10px;

    &.small-bar {
      padding: 20px;
      padding-top: 10px;
    }
  }

  .content-container--actions.full-width {
    padding: 20px 30px;
    width: 100%;

    &.small-bar {
      padding: 20px;
      padding-top: 10px;
    }
  }
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {
}

// LAPTOP
@include media-query-for(laptop) {
}

// IPAD PRO
@include media-query-for(ipadpro) {
}

// IPHONE SE
@include media-query-for(iphoneSE) {
  // FIXED ACTIONS BLOCK
  .fixed-actions-bar {
    flex-direction: column;

    .left-action-block {
      width: 100%;
      padding: 30px;
      padding-bottom: 0;
      margin-left: 0;

      &.small-bar {
        width: 100%;
        padding: 30px;
        padding-bottom: 0;
        margin-left: 0;
        padding: 20px;
        padding-bottom: 5px;

        .input-item {
          &.action {
            margin-right: 0;
          }
        }
      }

      .input-item {
        &:first-of-type {
          margin-right: 5px;
        }

        &:nth-of-type(2) {
          margin-left: 5px;
        }

        &.action {
          width: 100%;
          .button,
          button.button {
            width: 100%;
          }
        }
      }
    }

    .content-container--actions {
      width: 100%;
      flex-direction: row;
      margin-top: 0;

      &.with-input {
        padding-top: 0;
      }

      button.button {
        &:first-of-type {
          margin-left: 0;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }

    .content-container--actions.full-width {
    }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {
}

// IPAD PORTRAIT
@include media-query-for(ipad) {
}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {
}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {
}
