@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// BUTTON
.button,
button.button {
  @include flex($justify: center);
  position: relative;
  color: color(white-color);
  font-family: font(main);
  font-weight: 500;
  min-width: 100px;
  min-height: 45px;
  padding: 7px 17px;
  // margin-left: 5px;
  // margin-right: 5px;
  border-radius: radius(primary) !important;
  transition: all 200ms ease 0s;

  &:hover {
    color: color(white-color);
  }

  &--lg {
    @extend .button;
    font-size: size(lg);
    padding: 20px 30px;
  }

  &--md {
    @extend .button;
    font-size: size(md);
    padding: 15px 25px;
  }

  &--sm {
    @extend .button;
    font-size: size(xxs);
    padding: 10px 20px;
    border-radius: radius(round) !important;
  }

  &--xs {
    @extend .button;
    font-size: size(xxxs);
    font-weight: 500;
    min-width: 80px;
    min-height: 40px;
    padding: 7px 17px;
  }

  &--xxs {
    @extend .button;
    font-size: size(xxxxs);
    font-weight: 500;
    min-width: 70px;
    min-height: 35px;
    padding: 7px 13px;

    &.badge {
      font-weight: 500;
      border: 2px solid !important;
      min-width: 100px;
    }
  }

  &--xxxs {
    @extend .button;
    font-size: size(xxxxs);
    font-weight: 400;
    min-width: 60px;
    min-height: 25px;
    padding: 5px 10px;

    &.badge {
      font-weight: 500;
      border: 2px solid;
      min-width: 100px;
      cursor: default;
    }
  }

  &--icon {
    @extend .button;
    min-width: fit-content;
    min-height: 30px;
    padding: 5px;

    svg {
      margin-right: 0;
    }
  }

  &--input-button {
    font-size: size(xxxxxs);
    width: max-content;
    position: absolute;
    right: 0;
    // padding: 5px 15px;
    padding: 5px 10px;
    margin-top: 2px;
    margin-right: 5px;
    border-radius: radius(chipped) !important;

      svg {
        margin-right: 5px;
      }
  }

  svg {
    margin-right: 10px;
  }

  .button-left-icon {
    margin-right: 10px;
  }
}

.button.fit-content {
  width: fit-content;
}

// BUTTON SUB ACTIONS
.sub-actions-wrapper {
}

.button-sub-actions-container {
  position: relative;

  .sub-actions {
    @include flex($direction: column);
    background: color(white-color);
    z-index: 9998;
    position: absolute;
    width: 100px;
    min-height: 100px;
    border-radius: radius(rounder);
    box-shadow: 0px 0px 30px -15px #00000085;
    padding: 10px;
    padding-top: 20px;
    margin-top: 5px;

    .button {
      margin-bottom: 10px;
    }
  }
}

.button-sub-actions-container.visible {
  display: block;
}

.button-sub-actions-container.hidden {
  display: none;
}

// LABEL BUTTON GROUP
.label-button-group {
  @include flex();
  width: 100%;

  .input-block-container {
    width: 100%;
  }

  .button-group-container {
    @include flex();
    width: 100%;
  }
}

// BUTTON CONTAINER
.button-container {
  padding-top: 15px;
  margin-left: 5px;
  margin-right: 5px;

  &--flex {
    @extend .button-container;
    @include flex($justify: flex-start);
  }

  &--flex-end {
    @extend .button-container;
    @include flex($justify: flex-end);
  }

  &--flex-between {
    @extend .button-container;
    @include flex($justify: space-between);
  }
}

// LOADING ANIMATION
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {
  // BUTTON
  .button {
    font-size: size(xxxs);
    margin: 0;
  }

  // LABEL BUTTON GROUP
  .label-button-group {
    flex-direction: column;

    .input-block-container {
      padding-bottom: 10px;
    }

    .button-group-container {
      width: 100%;
      padding-bottom: 20px;
    }
  }

  // BUTTON CONTAINER
  .button-container {
    .button,
    button.button {
      margin-right: 5px;

      &--xs {
        min-width: 30px;
        min-height: 30px;
        padding: 6px 10px;
        margin-right: 6px;
      }
    }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {
  // BUTTON
  .button,
  button.button {
    font-size: size(xxxs);
    text-align: center;
    padding: 5px 17px;
  }

  // BUTTON SUB ACTIONS
  .button-sub-actions-container {
    .sub-actions {
      .button {
        width: 100%;
      }
    }
  }
}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {
  // BUTTON SUB ACTIONS
  .button-sub-actions-container {
    .sub-actions {
      .button {
        width: 100%;
      }
    }
  }
}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}
