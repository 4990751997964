@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// MODALS
.close-action-container {
  @include flex($justify: flex-end);
  width: 100%;
  position: absolute;
  top: 20px;
  right: 15px;
}

.content-description {
  font-size: size(xs);
  margin-left: 5px;
  margin-bottom: 15px;
}

// CENTER TITLE
.center-title {
  @include flex($justify: center);
  width: 100%;
}

// MEDIA QUERIES

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptop) {}

// IPAD PRO
@include media-query-for(ipadpro) {}

// IPHONE SE
@include media-query-for(iphoneSE) {
  .reverse-on-mobile {
      flex-direction: column-reverse;

      .button,
      button.button,
      .button--xs,
      button.button--xs {
          &:nth-last-child(2) {
              margin-top: 15px !important;
          }
      }
  }
}

// IPHONE X
@include media-query-for(iphoneX) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadLandscape) {}

// IPHONE X PLUS
@include media-query-for(iphoneXPlus) {}
