@use '../functions/theme' as *;
@use '../helpers/mixins' as *;

// TOP HEADER
.top-header-container {
  @include flex($justify: flex-end, $align: center);
  background: linear-gradient(90deg, #85AE8F, #68ABC2);
  padding: 5px 3vw;
  transition: all 0.3s ease;

  &.on-scroll {
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  .search-block {
    width: min(400px, 100%);

    .input-block-container {
      margin: unset;
      border: none !important;

      .padded__control {
        height: 30px;
        min-height: unset;
        padding: 0 30px !important;

        // FOCUSED STATE
        &--is-focused {
          min-height: 30px;
          height: 30px;

          border: none !important;
          box-shadow: unset;

          padding: unset !important;
          transition: unset !important;
        }

        .padded__value-container {
          padding: 0 0 0 30px !important;
        }
      }

      .padded__value-container {
        position: absolute;
        top: 51%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;

        .padded__placeholder {
          // font-size: size(xxs);
          opacity: 0.75;
        }
      }

      .padded__indicators {
        position: absolute;
        top: 51%;
        right: 0;
        transform: translateY(-50%);
        padding: unset;
      }
    }
  }
}

// HEADER NAVIGATION
.header-navigation-container {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: fit-content;
  // padding: 0 3vw;
  background: white;
  box-shadow: 0 5px 3px rgba(100,106,110,.26),0 9px 3px rgba(100,106,110,.1),0 10px 3px rgba(100,106,110,.06);
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

  opacity: 1;
  visibility: visible;

  @media (width < 1250px) {
    .social-icon.mobile-menu-toggle {
      display: block !important;
    }
  }

  @media (width < 750px) {
    .social-icon-set {
      display: none !important;
    }
  }

  @media (width < 550px) {
    .logo-block {
      width: auto;
      margin-right: 100px;
    }
  }
}

// HEADER NAVIGATION - ON SCROLL
.header-navigation-container.on-scroll {
  backdrop-filter: blur(15px);
  background: linear-gradient(45deg, #2A4150e0, #1F3241e0 70%, #152633e0 91%);
  // background: $header-navigation-background;
  background: white;
  box-shadow: $header-navigation-box-shadow;

  .logo-block {
    button {
      .image {
        height: 80px;
      }
    }
  }
}

/* FADE-OUT STATE WHEN OPACITY IS SET TO 0 */
.header-navigation-container[style*="opacity: 0"] {
  visibility: hidden;
}

// HEADER NAVIGATION - PRIMARY
.header-navigation {
  @include flex($justify: space-between, $wrap: wrap);
  z-index: 3;
  width: 100%;
  padding: 0 3vw;

  // FEATURES BLOCK
  .features-block {
    @include flex();
    height: 140px;
    padding: 0 80px;

    .logo-block {
      width: 20%;

      .image {
        width: 180px;
      }
    }

    .icons-block {
      @include flex($justify: flex-end);
      width: 25%;
      padding-left: 30px;

      .a,
      .button {
        border-radius: radius(circle) !important;
        font-size: size(xxxs);
        padding: 7px 30px;
      }

      .social-icon {
        width: 45px;
        height: 45px;
        padding: 10px 12px;
        margin-right: 8px;
        transition: transition(alt);

        &:hover {
          transform: scale(1.1);
        }

        &.square {
          border-radius: 0 !important;
        }

        &.round {
          border-radius: radius(roundest) !important;
        }

        &.circle {
          border-radius: radius(circle) !important;
        }
      }
    }
  }

  // LOGO BLOCK
  .logo-block {
    // margin-right: auto;
    // @include flex($justify: center);

    button {
      position: relative;
      height: 100%;

      // HEADER DECORATION ELEMENT "/"
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -100px;
        height: 100%;
        width: 10px;
        background: linear-gradient(90deg, rgba(0, 0, 0, .3), transparent);
        transform: skew(-45deg) translateX(-40px);
      }

      .image {
        height: 100px;
        // height: clamp(3rem, calc(2rem + 2.5vw), 5rem);
        opacity: 0.85;
        transition: transition(primary);
      }
    }
  }

  // NAVIGATION BLOCK
  .menu-items-block {
    @include flex();
    position: relative;
    // height: 70px;
    // margin: 0 auto;

    .menu-items {
      @include flex();
      column-gap: 3vw;
      padding: 0;


      li {
        margin: 0 !important;

        a,
        .button {
          display: inline-block;
          min-width: unset;
          min-height: unset;
          font-weight: 600;
          font-size: size(md);
          padding: 0 !important;

          &::after {
            bottom: -5px;
          }

          &:hover {
            &::after {
              background-color: $hover-animation-underline-color--before;
            }
          }
        }
      }

      .submenu-items-block {
        &::before {
          content: '';
          position: absolute;
          top: -20px;
          left: 0;
          z-index: 10;
          width: 75%;
          max-width: 200px;
          height: 20px;
          border-radius: 30px;
          background-color: transparent;
        }

        li {
          padding: 10px 0px !important;
          margin-right: 0px !important;

          a,
          .button {
            display: inline-block;
            position: relative;
            font-size: size(sm);
            font-weight: 400 !important;
            justify-content: flex-start;
            text-align: left !important;

            &::after {
              content: '';
              position: absolute;
              width: 100%;
              transform: scaleX(0);
              height: 3px;
              bottom: -5px;
              left: 0;
              background-color: $hover-animation-underline-color--submenu;
              border-radius: radius(circle);
              transform-origin: right;
              // transition: transform 200ms ease-out;
            }

            &:hover::after {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }

        // DEFAULT
        &.default {
          li {
            padding: 10px 0 !important;
          }
        }
      }
    }

    .icons-block {
      @include flex($justify: flex-end);
      width: 20%;
      padding-left: 30px;
      // background: coral;

      .a,
      .button {
        border-radius: radius(circle) !important;
        font-size: size(xxxs);
        padding: 7px 30px;
      }

      .social-icon {
        width: 45px;
        height: 45px;
        padding: 10px 12px;
        margin-right: 8px;
        // transition: transition(alt);

        &:hover {
          transform: scale(1.1);
          // transition: transition(secondary);
        }

        &.square {
          border-radius: 0 !important;
        }

        &.round {
          border-radius: radius(roundest) !important;
        }

        &.circle {
          border-radius: radius(circle) !important;
        }
      }
    }

    &.on-scroll {
      .menu-items {
        li {
          margin-right: 40px;

          a,
          .button {
            display: inline-block;
            min-width: unset;
            min-height: unset;
            font-weight: 500;
            font-size: size(xs);
            padding: 0 !important;
          }
        }

        .submenu-items-block {
          li {

            a,
            .button {
              font-size: size(xs);
              font-weight: 500 !important;
            }
          }
        }
      }
    }
  }

  // HEADER NAVIGATION - SECONDARY
  .header-navigation-secondary {
    @include flex($align: center, $justify: center);
    // width: 50%;
    height: 60px;
    // margin-left: auto;

    .mobile-menu-toggle {
      display: block;
    }

    .icons-block {
      @include flex($align: center, $justify: flex-end, $wrap: wrap-reverse);
      column-gap: 10px;
      row-gap: 5px;

      .social-icon {
        @include flex($align: center, $justify: center);
        width: 45px;
        height: 45px;
        border-radius: 100px !important;

        // transition: transition(alt);
        &.mobile-menu-toggle {
          display: none;
        }

        // &.member-portal {
        //   column-gap: 5px;
        //   width: fit-content;
        //   padding: 0 10px;
        //   font-size: 18px;
        //   text-transform: uppercase;
        //   background: linear-gradient(to bottom, #bf953f, #b38728, #aa771c);

        //   &:hover svg {
        //     transform: none;
        //   }
        // }

        &:hover {
          transform: scale(1.1);

          svg {
            transform: scale(1.1);
          }
        }

        &.square {
          border-radius: 0 !important;
        }

        &.round {
          border-radius: radius(roundest) !important;
        }

        &.circle {
          border-radius: radius(circle) !important;
        }
      }
    }
  }
}

// SEARCH BLOCK
.search-block {
  width: 55%;

  &.full-width {
    width: 100%;
  }

  .input-block-container {
    margin-bottom: 0;

    // INPUT
    .input-block {
      border-radius: radius(circle);
      font-size: size(xs);
      border: 1px solid color(info-color);

      &::placeholder {
        font-size: size(xs);
        font-weight: 400;
        color: color(mid-grey-color);
      }
    }

    // REACT SELECT OVERRIDE
    .css-yk16xz-control {
      font-size: size(xs);
      min-height: 52px;
      // transition: none;
      border-radius: radius(primary);
      border: 1px solid color(primary-lighter-color);
      border-radius: radius(circle);
      padding-left: 30px;
      padding-right: 20px;
      margin-bottom: 0;
    }

    .css-yk16xz-control.bordered {
      border: 0.5px solid color(grey-color);
    }

    // FOCUSED STATE
    .css-1pahdxg-control {
      font-size: size(xs);
      min-height: 52px;
      background: color(light-color);
      border: 1px solid color(info-color);
      border-radius: radius(circle);
      padding-left: 30px;
      padding-right: 20px;
      margin-bottom: 0;
    }

    .css-1wa3eu0-placeholder {
      margin-top: 0px;
    }
  }
}

// --MEDIA QUERIES
@media screen and (max-width: 1200px) {
  .header-navigation-container {
    .header-navigation {
      flex-wrap: nowrap;

      .header-navigation-secondary {
        .social-icon {
          &.mobile-menu-toggle {
            display: block;
          }
        }
      }

      .menu-items-block {
        display: none;
      }
    }
  }
}

// OTHER DEVICES
@include media-query-for(others) {}

// LAPTOP
@include media-query-for(laptopretina) {}

//IPAD PRO
@include media-query-for(ipadpro) {}

//IPHONE SE
@include media-query-for(iphonese) {

  // MOBILE : HEADER NAVIGATION
  .header-navigation-container {
    // height: 100px;

    .header-navigation {
      display: none;
    }
  }

  // SEARCH BLOCK
  .search-block {
    width: 55%;

    &.full-width {
      width: 100%;
    }

    .input-block-container {

      // INPUT
      .input-block {
        font-size: size(xxs);

        &::placeholder {
          font-size: size(xxs);
        }
      }
    }
  }
}

//IPHONE X
@include media-query-for(iphonex) {}

// IPAD PORTRAIT
@include media-query-for(ipad) {}

// IPAD LANDSCAPE
@include media-query-for(ipadlandscape) {}

// IPHONE X PLUS
@include media-query-for(iphonexplus) {}